import { useLocation, Link as RouterLink } from "react-router-dom";

import { MenuItem, useMediaQuery, Divider, Grid } from "@material-ui/core";
import { useMenuStyles } from "../styles/muiStyles";
import { useTheme } from "@material-ui/core/styles";
// import PublicIcon from "@material-ui/icons/Public";
// import RewardIcon from '@material-ui/icons/EmojiEventsOutlined';
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
// import PeopleIcon from "@material-ui/icons/People";
// import WalletIcon from "@material-ui/icons/AccountBalanceWallet";
import StoreIcon from "@material-ui/icons/Store";
import { useAuthContext } from "../context/auth";

import FeedIcon from "../assets/Feed.svg";
import RewardIcon from "../assets/Reward.svg";
import LeaderBoardIcon from "../assets/LeaderBoard.svg";
import AddNFTIcon from "../assets/AddNFT.svg";

const DesktopNavMenu = () => {
  const { pathname } = useLocation();
  const { user } = useAuthContext();
  const classes = useMenuStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  if (isMobile) return null;

  return (
    <div>
      <div className={classes.list}>
        <MenuItem
          selected={
            pathname === "/" ||
            (!pathname.startsWith("/tag") &&
              !pathname.startsWith("/reward") &&
              !pathname.startsWith("/leaderboard") &&
              !pathname.startsWith("/mint") &&
              !pathname.startsWith("/marketplace"))
          }
          component={RouterLink}
          to="/"
          className={classes.menuItem}
        >
          {/* <PublicIcon className={classes.menuIcon} /> */}
          <img
            src={FeedIcon}
            alt="Feed Icon"
            className={`${classes.menuIcon} ${classes.svgImage}`}
          />
          Feed
        </MenuItem>
        <MenuItem
          selected={pathname.startsWith("/reward")}
          component={RouterLink}
          to={`/reward`}
          className={classes.menuItem}
        >
          {/* <RewardIcon className={classes.menuIcon} /> */}
          <img
            src={RewardIcon}
            alt="Reward Icon"
            className={`${classes.menuIcon} ${classes.svgImage}`}
          />
          Reward
        </MenuItem>
        <MenuItem
          selected={pathname.startsWith("/tag")}
          component={RouterLink}
          to="/tags"
          className={classes.menuItem}
        >
          <LocalOfferIcon className={classes.menuIcon} />
          Tags
        </MenuItem>
        <MenuItem
          selected={pathname.startsWith("/leaderboard")}
          component={RouterLink}
          to="/leaderboard"
          className={classes.menuItem}
        >
          {/* <PeopleIcon className={classes.menuIcon} /> */}
          <img
            src={LeaderBoardIcon}
            alt="Leader Board Icon"
            className={`${classes.menuIcon} ${classes.svgImage}`}
          />
          Leaderboard
        </MenuItem>
        <MenuItem
          selected={pathname.startsWith("/mint")}
          component={RouterLink}
          to="/mint"
          className={classes.menuItem}
        >
          {/* <WalletIcon className={classes.menuIcon} /> */}
          <img
            src={AddNFTIcon}
            alt="Mint Icon"
            className={`${classes.menuIcon} ${classes.svgImage}`}
          />
          Mint
        </MenuItem>
        <MenuItem
          selected={pathname.startsWith("/marketplace")}
          component={RouterLink}
          to="/marketplace"
          className={classes.menuItem}
        >
          <StoreIcon className={classes.menuIcon} />
          Marketplace
        </MenuItem>
      </div>
      {/* <Divider orientation="vertical" flexItem /> */}
    </div>
  );
};

export default DesktopNavMenu;
