import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import "./index.css";
import "typeface-montserrat";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import apolloClient from "./apolloClient";
import { AuthProvider } from "./context/auth";
import { StateProvider } from "./context/state";

const root = createRoot(document.getElementById("root"));
root.render(
  <ApolloProvider client={apolloClient}>
    <Router>
      <AuthProvider>
        <StateProvider>
              <App />
        </StateProvider>
      </AuthProvider>
    </Router>
  </ApolloProvider>
);
