import { useState, useEffect } from "react";
import { useStateContext } from "../context/state";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import NavMenuMobile from "./NavMenuMobile";
import UserMenuMobile from "./UserMenuMobile";
import UserMenuDesktop from "./UserMenuDesktop";
import SearchBar from "./SearchBar";
// import DarkModeSwitch from "./DarkModeSwitch";
import { useAuthContext } from "../context/auth";
import PuchoooLogo from "../assets/logo.png";
import { getErrorMsg } from "../utils/helperFuncs";

import {
  AppBar,
  // Toolbar,
  Typography,
  // Link,
  Button,
  // Container,
  useMediaQuery,
  IconButton,
  // Grid,
} from "@material-ui/core";
import { Grid, Dialog, DialogTitle, DialogContent } from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
// import { Grid } from "@mui/material";
import {
  useAskQuesPageStyles,
  useDialogStyles,
  useNavStyles,
} from "../styles/muiStyles";
import AuthFormModal from "../components/AuthFormModal";
import { useTheme } from "@material-ui/core/styles";
// import FavoriteIcon from "@material-ui/icons/Favorite";
import SearchIcon from "@material-ui/icons/Search";
import AskForm from "./AskForm";
import { useMutation } from "@apollo/client";
import { LOGOUT_USER } from "../graphql/mutations";

const NavBar = ({ modalOpen, setModalOpen, handleClickOpen }) => {
  const { user, logoutUser } = useAuthContext();
  const [errorMsg, setErrorMsg] = useState(null);
  const { clearEdit, fcmToken } = useStateContext();
  const [searchOpen, setSearchOpen] = useState(false);
  const client = useApolloClient();
  const location = useLocation();
  const classes = useNavStyles();
  const askClasses = useAskQuesPageStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile_Auth_Bar = useMediaQuery(theme.breakpoints.down("900"));
  const isMobile_Reward_Heading = useMediaQuery(theme.breakpoints.down("450"));

  const Modalclasses = useDialogStyles();

  const [userLogout, { loading }] = useMutation(LOGOUT_USER, {
    onError: (err) => {
      setErrorMsg(getErrorMsg(err));
    },
  });

  useEffect(() => {
    if (!isMobile && searchOpen) {
      setSearchOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  const handleLogout = () => {
    userLogout({
      variables: { id: user.id, fcmToken: fcmToken },
      update: (_, { data }) => {
        logoutUser();
        client.resetStore();
      },
    });
  };

  const handleClick = () => {
    handleClickOpen();
    clearEdit();
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <AppBar
        position="sticky"
        color="inherit"
        elevation={1}
        className={classes.appBar}
      >
        {/* <Toolbar variant="dense">
        {!searchOpen && (
          <Container disableGutters className={classes.contentContainer}>
            <div className={classes.leftPortion}>
              <div className={classes.logoWrapper}>
                {isMobile && <NavMenuMobile />}
                {isMobile ? (
                  <IconButton
                    className={classes.logo}
                    component={RouterLink}
                    to="/"
                  >
                    <img
                      src={PuchoooLogo}
                      style={{ margin: "0 auto" }}
                      width="40px"
                      alt="pochooo-logo"
                    />
                  </IconButton>
                ) : (
                  <Button
                    className={classes.logo}
                    component={RouterLink}
                    to="/"
                    size="large"
                  >
                    <img
                      src={PuchoooLogo}
                      width="100px"
                      alt="pochooo-logo"
                      style={{ margin: "0 auto" }}
                    />
                  </Button>
                )}
              </div>
              <div className={classes.topBar}>
                <Typography
                  variant={isMobile ? "h6" : "h5"}
                  color="secondary"
                  style={{ wordWrap: "anywhere", marginRight: '1em' }}
                >
                  Home
                  {/* {tagFilterActive
            ? `Questions tagged [${tagName}]`
            : searchFilterActive
            ? `Search results for "${query}"`
            : 'All Questions'} 
                </Typography>
              </div>
              {!isMobile && <SearchBar />}
              {user ? (
                <Button
                  variant="contained"
                  size={isMobile ? "small" : "medium"}
                  component={RouterLink}
                  to="/ask"
                  onClick={() => clearEdit()}
                  className={classes.askQuesBtn}
                >
                  Ask Questions
                </Button>
              ) : (
                <AuthFormModal buttonType="ask" />
              )}
            </div>
            {isMobile ? (
              <>
                <IconButton
                  color="primary"
                  className={classes.searchBtn}
                  onClick={() => setSearchOpen((prevState) => !prevState)}
                >
                  <SearchIcon />
                </IconButton>
                <UserMenuMobile user={user} logoutUser={handleLogout} />
              </>
            ) : (
              <>
                <UserMenuDesktop user={user} logoutUser={handleLogout} />
              </>
            )}
          </Container>
        )}
        {searchOpen && isMobile && (
          <SearchBar isMobile={isMobile} setSearchOpen={setSearchOpen} />
        )}
      </Toolbar> */}
        {!searchOpen && (
          <Grid container>
            {isMobile && (
              <Grid item xs={1}>
                <NavMenuMobile />
              </Grid>
            )}

            <Grid
              item
              xl={1.3}
              lg={1.3}
              md={1.7}
              sm={3}
              xs={2}
              className={classes.navbarLogoContainer}
            >
              {/* <Button
            className={classes.logo}
            component={RouterLink}
            to="/"
            size="large"
          >
            <img
              src={PuchoooLogo}
              width={isMobile? "40px" :"100px"}
              alt="pochooo-logo"
              style={{ margin: isMobile? "0px": "0 auto" }}
            />
          </Button> */}
              {isMobile ? (
                <IconButton
                  className={classes.logo}
                  component={RouterLink}
                  to="/"
                >
                  <img
                    src={PuchoooLogo}
                    style={{ marginLeft: "0.5em" }}
                    width="40px"
                    alt="puchooo-logo"
                  />
                </IconButton>
              ) : (
                <Button
                  className={classes.logo}
                  component={RouterLink}
                  to="/"
                  size="large"
                >
                  <img
                    src={PuchoooLogo}
                    width="100px"
                    alt="puchooo-logo"
                    style={{ margin: "0 auto" }}
                  />
                </Button>
              )}
            </Grid>

            <Grid
              item
              xl={10.7}
              lg={10.7}
              md={10.3}
              sm={9}
              xs={9}
              className={classes.navbarMidContainer}
            >
              <Grid container>
                <Grid
                  item
                  xl={9}
                  lg={9}
                  md={10}
                  sm={10}
                  xs={10}
                  className={classes.navbarMid}
                >
                  <>
                    <div className={classes.topBar}>
                      <Typography
                        variant={isMobile ? "h6" : "h5"}
                        color="secondary"
                        style={{
                          wordWrap: "anywhere",
                          fontSize: isMobile
                            ? "15px"
                            : isMobile_Auth_Bar
                            ? "15px"
                            : "",
                          margin: isMobile ? "0 2em" : "0 1em",
                          fontWeight: isMobile ? "normal" : "bold",
                          display: isMobile_Reward_Heading ? "none" : "",
                        }}
                      >
                        {location.pathname === "/"
                          ? "Home"
                          : location.pathname === "/reward"
                          ? "Rewards"
                          : location.pathname === "/tags"
                          ? "Tags"
                          : location.pathname === "/ask"
                          ? "Ask"
                          : location.pathname === "/leaderboard"
                          ? "Leaderboard"
                          : location.pathname === "/mint"
                          ? "Mint NFT"
                          : location.pathname.startsWith("/search")
                          ? "Search Result"
                          : location.pathname === "/marketplace"
                          ? "Marketplace"
                          : location.pathname !== "/user/:username"
                          ? "Profile"
                          : "more"}
                      </Typography>
                    </div>

                    {!isMobile &&
                      !isMobile_Auth_Bar &&
                      location.pathname !== "/reward" &&
                      location.pathname !== "/tags" &&
                      location.pathname !== "/leaderboard" &&
                      location.pathname !== "/mint" &&
                      location.pathname !== "/marketplace" && <SearchBar />}

                    {location.pathname !== "/reward" &&
                    location.pathname !== "/tags" &&
                    location.pathname !== "/leaderboard" &&
                    location.pathname !== "/mint" &&
                    location.pathname !== "/marketplace" ? (
                      user ? (
                        <Button
                          variant="contained"
                          size={isMobile ? "small" : "medium"}
                          // component={RouterLink}
                          // to="/ask"
                          onClick={handleClick}
                          className={classes.askQuesBtn}
                        >
                          Ask Question
                        </Button>
                      ) : (
                        <AuthFormModal buttonType="ask" />
                      )
                    ) : (
                      ""
                    )}
                  </>
                </Grid>
                <Grid item xl={3} lg={3} md={2} sm={2} xs={2}>
                  {/* {isMobile || isMobile_Auth_Bar ? (
                  <>
                    <UserMenuMobile user={user} logoutUser={handleLogout} />
                  </>
                ) : (
                  <>
                    <UserMenuDesktop user={user} logoutUser={handleLogout} />
                  </> */}
                  {isMobile || isMobile_Auth_Bar ? (
                    <>
                      {location.pathname !== "/reward" ? (
                        <Grid container alignItems={"center"}>
                          <Grid item xs={6}>
                            <IconButton
                              color="primary"
                              className={classes.searchBtn}
                              onClick={() =>
                                setSearchOpen((prevState) => !prevState)
                              }
                            >
                              <SearchIcon />
                            </IconButton>
                          </Grid>
                          <Grid item xs={6}>
                            <UserMenuMobile
                              user={user}
                              logoutUser={handleLogout}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <UserMenuMobile user={user} logoutUser={handleLogout} />
                      )}
                    </>
                  ) : (
                    <>
                      <UserMenuDesktop user={user} logoutUser={handleLogout} />
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {searchOpen && isMobile_Auth_Bar && !isMobile && (
          <SearchBar
            isMobile_Auth_Bae={isMobile_Auth_Bar}
            setSearchOpen={setSearchOpen}
          />
        )}
        {searchOpen && isMobile && (
          <SearchBar isMobile={isMobile} setSearchOpen={setSearchOpen} />
        )}
        {/* <Toolbar variant="dense">
        {!searchOpen && (
          <Container disableGutters className={classes.contentContainer}>
            <div className={classes.leftPortion}>
              <div className={classes.logoWrapper}>
                {isMobile && <NavMenuMobile />}
                {isMobile ? (
                  <IconButton
                    className={classes.logo}
                    component={RouterLink}
                    to="/"
                  >
                    <img
                      src={PuchoooLogo}
                      style={{ margin: "0 auto" }}
                      width="40px"
                      alt="pochooo-logo"
                    />
                  </IconButton>
                ) : (
                  <Button
                    className={classes.logo}
                    component={RouterLink}
                    to="/"
                    size="large"
                  >
                    <img
                      src={PuchoooLogo}
                      width="100px"
                      alt="pochooo-logo"
                      style={{ margin: "0 auto" }}
                    />
                  </Button>
                )}
              </div>
              <div className={classes.topBar}>
                <Typography
                  variant={isMobile ? "h6" : "h5"}
                  color="secondary"
                  style={{ wordWrap: "anywhere", marginRight: "1em" }}
                >
                  Home
                </Typography>
              </div>
              {!isMobile && <SearchBar />}
              {user ? (
                <Button
                  variant="contained"
                  size={isMobile ? "small" : "medium"}
                  component={RouterLink}
                  to="/ask"
                  onClick={() => clearEdit()}
                  className={classes.askQuesBtn}
                >
                  Ask Questions
                </Button>
              ) : (
                <AuthFormModal buttonType="ask" />
              )}
            </div>
            {isMobile ? (
              <>
                <IconButton
                  color="primary"
                  className={classes.searchBtn}
                  onClick={() => setSearchOpen((prevState) => !prevState)}
                >
                  <SearchIcon />
                </IconButton>
                <UserMenuMobile user={user} logoutUser={handleLogout} />
              </>
            ) : (
              <>
                <UserMenuDesktop user={user} logoutUser={handleLogout} />
              </>
            )}
          </Container>
        )}
        {searchOpen && isMobile && (
          <SearchBar isMobile={isMobile} setSearchOpen={setSearchOpen} />
        )}
      </Toolbar> */}
      </AppBar>

      <div style={{ display: "inline" }}>
        <Dialog
          PaperProps={{
            style: {
              width: "420px",
              background: "#0D0D0D",
              boxShadow: "none",
              borderRadius: "12.5px",
            },
          }}
          open={modalOpen}
          onClose={handleModalClose}
          maxWidth="sm"
          classes={{ paper: Modalclasses.dialogWrapper }}
        >
          <CloseIcon
            onClick={handleModalClose}
            className={askClasses.closeBtn}
          />
          <DialogTitle></DialogTitle>
          <DialogContent>
            <AskForm handleModalClose={handleModalClose} />
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default NavBar;
