import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { POST_QUESTION, EDIT_QUESTION } from "../graphql/mutations";
import { useStateContext } from "../context/state";
import ErrorMessage from "../components/ErrorMessage";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { getErrorMsg } from "../utils/helperFuncs";

// import {
//   Typography,
//   TextField,
//   Button,
//   InputAdornment,
//   Chip,
// } from "@material-ui/core";
// import Autocomplete from "@material-ui/lab/Autocomplete";
import { useAskQuesPageStyles, useDialogStyles } from "../styles/muiStyles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
// import DialogTitle from "@mui/material/DialogTitle";
// import DialogContent from "@mui/material/DialogContent";
// import DialogActions from "@mui/material/DialogActions";
// import IconButton from "@mui/material/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import UpdateProfileForm from "../components/UpdateProfileForm";

const validationSchema = yup.object({
  title: yup
    .string()
    .required("Required")
    .min(15, "Must be at least 15 characters"),
  body: yup
    .string()
    .required("Required")
    .min(30, "Must be at least 30 characters"),
});

const UpdateProfilePage = ({ openEditModal, setOpenEditModal }) => {
  const classes = useAskQuesPageStyles();
  const Modalclasses = useDialogStyles();
  const history = useHistory();
  const { editValues, clearEdit, notify } = useStateContext();
  const [tagInput, setTagInput] = useState("");
  const [tags, setTags] = useState(editValues ? editValues.tags : []);
  const [errorMsg, setErrorMsg] = useState(null);
  const [modalOpen, setModalOpen] = useState(true);

  useEffect(() => {
    if (!modalOpen) {
      setModalOpen(true);
    }
  }, [modalOpen]);
  const { register, handleSubmit, reset, errors } = useForm({
    defaultValues: {
      title: editValues ? editValues.title : "",
      body: editValues ? editValues.body : "",
    },
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const [addQuestion, { loading: addQuesLoading }] = useMutation(
    POST_QUESTION,
    {
      onError: (err) => {
        setErrorMsg(getErrorMsg(err));
      },
    }
  );

  const [updateQuestion, { loading: editQuesLoading }] = useMutation(
    EDIT_QUESTION,
    {
      onError: (err) => {
        setErrorMsg(getErrorMsg(err));
      },
    }
  );

  const postQuestion = ({ title, body }) => {
    if (tags.length === 0) return setErrorMsg("Atleast one tag must be added.");

    addQuestion({
      variables: { title, body, tags },
      update: (_, { data }) => {
        history.push(`/questions/${data.postQuestion.id}`);
        reset();
        notify("Question posted!");
      },
    });
  };

  const editQuestion = ({ title, body }) => {
    if (tags.length === 0) return setErrorMsg("Atleast one tag must be added.");

    updateQuestion({
      variables: { quesId: editValues.quesId, title, body, tags },
      update: (_, { data }) => {
        history.push(`/questions/${data.editQuestion.id}`);
        clearEdit();
        notify("Question edited!");
      },
    });
  };

  const handleTags = (e) => {
    if (!e || (!e.target.value && e.target.value !== "")) return;
    const value = e.target.value.toLowerCase().trim();
    setTagInput(value);

    const keyCode = e.target.value
      .charAt(e.target.selectionStart - 1)
      .charCodeAt();

    if (keyCode === 32 && value.trim() !== "") {
      if (tags.includes(value))
        return setErrorMsg(
          "Duplicate tag found! You can't add the same tag twice."
        );

      if (!/^[a-zA-Z0-9-]*$/.test(value)) {
        return setErrorMsg("Only alphanumeric characters & dash are allowed.");
      }

      if (tags.length >= 5) {
        setTagInput("");
        return setErrorMsg("Max 5 tags can be added! Not more than that.");
      }

      if (value.length > 15) {
        return setErrorMsg("A single tag can't have more than 15 characters.");
      }

      setTags((prevTags) => [...prevTags, value]);
      setTagInput("");
    }
  };

  // const [open, setOpen] = useState(false);
  // const handleModalClose = () => {
  //   setModalOpen(false);
  // };
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  return (
    <div style={{ display: "inline" }}>
      <Dialog
        PaperProps={{
          style: {
            width: "420px",
            background: "#0D0D0D",
            boxShadow: "none",
            borderRadius: "12.5px",
          },
        }}
        // open={modalOpen}
        // onClose={handleModalClose}
        open={openEditModal}
        onClose={handleCloseEditModal}
        maxWidth="sm"
        classes={{ paper: Modalclasses.dialogWrapper }}
      >
        <CloseIcon
          onClick={handleCloseEditModal}
          className={classes.closeBtn}
        />
        <DialogTitle></DialogTitle>
        <DialogContent>
          <UpdateProfileForm />
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default UpdateProfilePage;
