import { useState, useRef, useEffect } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
// import * as dotenv from "dotenv";
import { GET_USER, GET_QUESTIONS } from "../graphql/queries";
import { GET_ALL_COLLECTIONS } from "../graphql/queries";
import { REGISTER_USER, REWARD_USER } from "../graphql/mutations";
import RecentQuestions from "../components/RecentQuestions";
import ProfileTabBar from "../components/ProfileTabBar";
import LoadingSpinner from "../components/LoadingSpinner";
import { useStateContext } from "../context/state";
import { formatDateAgo, getErrorMsg } from "../utils/helperFuncs";
import {
  Avatar,
  Typography,
  Divider,
  Button,
  useMediaQuery,
  Link,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useUserPageStyles, useMarketPageStyles } from "../styles/muiStyles";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import { Grid, Chip } from "@mui/material";
import CardMedia from "@material-ui/core/CardMedia";
import { useAuthContext } from "../context/auth";
import ConnectWallet from "../components/ConnectWallet";
import MarketPage from "./MarketPlace";
import {
  ProviderRpcClient,
  Address,
  Contract,
} from "everscale-inpage-provider";
import { initVenomConnect } from "../venom-connect/configure";
import tokenWalletAbi from "../abi/TokenWallet.abi.json";
import tokenRootAbi from "../abi/TokenRoot.abi.json";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "250px",
    position: "relative",
  },
  coverPhoto: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    top: 60,
    zIndex: 2,
    border: `5px solid ${theme.palette.background.paper}`,
  },
}));

const useprofileDetailStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  userContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  user_details_container: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  details_area: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    borderRadius: 8,
  },
  details: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  detailtxt: {
    fontSize: 20,
    fontWeight: "bold",
    color: "white",
    marginBottom: theme.spacing(1),
  },
  detailInfo: {
    color: "white",
    marginLeft: theme.spacing(1),
  },
}));

const UserPage = ({ handleClickOpenEditModal }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [changeBy, setChangeBy] = useState("Profile");
  const [sortBy, setSortBy] = useState("NEWEST");
  const [venomConnect, setVenomConnect] = useState();
  const [venomProvider, setVenomProvider] = useState();
  const [address, setAddress] = useState();
  const [pubKey, setPubkey] = useState("");
  let tokenWalletAddress; // User's TIP-3 TokenWallet address
  const { user } = useAuthContext();
  const classes = useUserPageStyles();
  const profileclasses = useStyles();
  const profiledetailsclasses = useprofileDetailStyles();
  const market_classes = useUserPageStyles();
  const { notify } = useStateContext();
  const { username } = useParams();
  const [errorMsg, setErrorMsg] = useState(null);
  const [sdesktop, setSDesktop] = useState(true);
  const [fetchedUser, setFetchedUser] = useState(null);
  const [userID, setUserID] = useState("");
  const [balance, setBalance] = useState("");
  const [transaction, setTransaction] = useState(false);
  const init = async () => {
    const _venomConnect = await initVenomConnect();
    setVenomConnect(_venomConnect);
  };

  useEffect(() => {
    init();
  }, []);

  const getAddress = async (provider) => {
    const providerState = await provider?.getProviderState?.();
    return providerState?.permissions.accountInteraction?.address.toString();
  };

  const getPubkey = async (provider) => {
    const providerState = await provider?.getProviderState?.();
    return providerState?.permissions.accountIntesraction?.publicKey;
  };

  // Any interaction with venom-wallet (address fetching is included) needs to be authentificated
  const checkAuth = async (_venomConnect) => {
    const auth = await _venomConnect?.checkAuth();
    if (auth) await getAddress(_venomConnect);
  };

  // This handler will be called after venomConnect.login() action
  // connect method returns provider to interact with wallet, so we just store it in state
  const onConnect = async (provider) => {
    setVenomProvider(provider);
    await onProviderReady(provider);
  };
  const onProviderReady = async (provider) => {
    const venomWalletAddress = provider
      ? await getAddress(provider)
      : undefined;
    const publicKey = provider ? await getPubkey(provider) : undefined;
    setAddress(venomWalletAddress);
    setPubkey(publicKey);
  };
  useEffect(() => {
    // connect event handler
    const off = venomConnect?.on("connect", onConnect);
    if (venomConnect) {
      checkAuth(venomConnect);
    }
    // just an empty callback, cuz we don't need it
    return () => {
      off?.();
    };
  }, [venomConnect]);
  const [fetchUser, { data, loading }] = useLazyQuery(GET_USER, {
    onError: (err) => {
      notify(getErrorMsg(err), "error");
    },
  });
  const [fetchQuestions, { qdata, loading: qloading }] = useLazyQuery(
    GET_QUESTIONS,
    {
      fetchPolicy: "network-only",
      onError: (err) => {
        notify(getErrorMsg(err), "error");
      },
    }
  );
  const getQues = (sortBy, page, limit, filterByTag, filterBySearch) => {
    fetchQuestions({
      variables: { sortBy, page, limit, filterByTag, filterBySearch },
    });
  };
  const [userReward, { data: rewardData, loading: rewardloading, error }] =
    useMutation(REWARD_USER, {
      onError: (err) => {
        setErrorMsg(getErrorMsg(err));
      },
    });

  const { data: userNFT, loading: userNFTLoading } = useQuery(
    GET_ALL_COLLECTIONS,
    {
      onError: (err) => {
        notify(getErrorMsg(err), "error");
      },
    }
  );

  useEffect(() => {
    fetchUser({ variables: { username } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  useEffect(() => {
    if (data) {
      setFetchedUser(data.getUser);
      console.log("data", data.getUser.account);
      setUserID(data.getUser.id);
    }
  }, [data]);

  const setupTokenWalletAddress = async () => {
    try {
      const contractAddress = new Address(
        "0:43d5c72f292e5dcf667480fe276d064728360521eeb5c9d7b31f47ead8f9238b"
      ); // Our TokenRoot address in venom testnet
      // We will use standalone-client form our venomConnect instance to call a view method of contract
      const contract = new venomProvider.Contract(
        tokenRootAbi,
        contractAddress
      ); // creating a contract instance with contract address and interface (ABI)
      // Smart-contract calling. Function walletOf of TokenRoot will calculate user's tokenWallet address by it's VenomWallet address (wich was connected)
      const tokenWallet = await contract.methods
        .walletOf({
          answerId: 0,
          walletOwner: data?.getUser?.account,
        })
        .call();
      if (!tokenWallet) return undefined;
      tokenWalletAddress = tokenWallet.value0._address;
      console.log("tokenwalletAddress", tokenWalletAddress);
      return tokenWalletAddress;
    } catch (e) {
      console.error(e);
    }
    return undefined;
  };
  // Same idea for token balance fetching. Usage of standalone client and balance method of TIP-3 TokenWallet
  // We already knows user's TokenWallet address
  const getBalance = async () => {
    if (!venomConnect) return;
    const standalone = await venomConnect?.getStandalone("venomwallet");
    if (standalone) {
      if (!tokenWalletAddress) {
        await setupTokenWalletAddress();
      }
      if (!venomProvider || !tokenWalletAddress) return;
      try {
        const contractAddress = new Address(tokenWalletAddress);
        const contract = new standalone.Contract(
          tokenWalletAbi,
          contractAddress
        );
        console.log("contract", contract);
        // We check a contract state here to acknowledge if TokenWallet already deployed
        // As you remember, wallet can be deployed with first transfer on it.
        // If our wallet isn't deployed, so it's balance is 0 :)
        const contractState = await venomProvider.rawApi.getFullContractState({
          address: tokenWalletAddress,
        });
        if (contractState.state) {
          // But if this deployed, just call a balance function
          const result = await contract.methods.balance({ answerId: 0 }).call();
          const tokenBalance = result.value0; // It will be with decimals. Format if you want by dividing with 10**decimals
          setBalance(tokenBalance / Math.pow(10, 18));
        } else {
          setBalance("0");
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      alert("Standalone is not available now");
    }
  };

  useEffect(() => {
    if (account) {
      getBalance();
    } else {
      return;
    }
  });
  if (loading || !fetchedUser) {
    return (
      <div style={{ minWidth: "100%", marginTop: "20%" }}>
        <LoadingSpinner size={80} />
      </div>
    );
  }
  const {
    id,
    account: account,
    rewards: rewards,
    username: userName,
    createdAt,
    reputation,
    totalQuestions,
    totalAnswers,
    recentQuestions,
    recentAnswers,
  } = fetchedUser;

  return (
    <div className={classes.root}>
      <ProfileTabBar
        isMobile={isMobile}
        changeBy={changeBy}
        setChangeBy={setChangeBy}
      />

      {changeBy === "Profile" && (
        <>
          <Card className={profileclasses.root}>
            <CardMedia
              className={profileclasses.coverPhoto}
              image={
                "https://miro.medium.com/v2/resize:fit:720/format:webp/1*w2Eec-hEt9pCU376JCxRGw.jpeg"
              }
              title="Cover Photo"
            />
            <Avatar
              className={profileclasses.avatar}
              alt={"ok"}
              src={`https://secure.gravatar.com/avatar/${id}?s=164&d=identicon`}
            />
          </Card>

          <Grid container className={profiledetailsclasses.root}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={profiledetailsclasses.userContainer}
            >
              <Typography variant="h4">{username}</Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={profiledetailsclasses.userContainer}
            >
              {user?.username === username && (
                <Chip
                  label="Edit Profile"
                  variant="outlined"
                  color="primary"
                  size="small"
                  component={RouterLink}
                  to={`/user/${user.username}/edit`}
                  clickable
                  sx={{
                    width: "90px",
                    color: "#ffffff",
                    borderImageSlice: 1,
                    borderImageSource:
                      "linear-gradient(91.6deg,#003638 0%, #72C974 70%)",
                  }}
                  onClick={handleClickOpenEditModal}
                />
              )}
            </Grid>

            <Grid
              item
              xs={12}
              className={profiledetailsclasses.user_details_container}
            >
              <div className={profiledetailsclasses.details_area}>
                <Typography
                  variant="h6"
                  className={profiledetailsclasses.detailtxt}
                >
                  Details
                </Typography>
                <div className={profiledetailsclasses.details}>
                  {/* <MaterialIcons
                    name={"leaderboard"}
                    size={20}
                    color={"white"}
                  /> */}
                  <Typography
                    variant="subtitle1"
                    className={classes.detailInfo}
                  >
                    Level : {reputation + " Reputation"}
                  </Typography>
                </div>
                <div className={classes.details}>
                  {/* <Ionicons name={"logo-bitcoin"} size={20} color={"white"} /> */}
                  <Typography
                    variant="subtitle1"
                    className={classes.detailInfo}
                  >
                    Tokens : {balance} IR
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.detailInfo}
                  >
                    Wallet : {account}
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </>
      )}
      {changeBy === "Questions" && (
        <div style={{ marginBottom: "1em" }}>
          {recentQuestions.length !== 0 ? (
            recentQuestions.map((q) => (
              <div key={q.id}>
                <RecentQuestions question={q} />
                <Divider />
              </div>
            ))
          ) : (
            <Typography variant="subtitle1">No questions asked yet.</Typography>
          )}
        </div>
      )}
      {changeBy === "NFT" && <MarketPage userID={username} />}
    </div>
  );
};

export default UserPage;
