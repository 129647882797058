import { useState, useRef, useEffect } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
// import * as dotenv from "dotenv";
import { GET_USER } from "../graphql/queries";
import { REGISTER_USER, REWARD_USER } from "../graphql/mutations";
import { useQuery } from "@apollo/client";
import RecentQuestions from "../components/RecentQuestions";
import LoadingSpinner from "../components/LoadingSpinner";
import PersonIcon from "@material-ui/icons/Person";
import { useStateContext } from "../context/state";
import { formatDateAgo, getErrorMsg } from "../utils/helperFuncs";
import { abi, IR_CONTRACT_ADDRESS } from "../constants";
import { ethers, Contract, providers, utils } from "ethers";
import {
  TextField,
  Avatar,
  Typography,
  Divider,
  Button,
  InputAdornment,
  Link,
  Chip,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useMarketPageStyles } from "../styles/muiStyles";
import { useAuthContext } from "../context/auth";
import ConnectWallet from "../components/ConnectWallet";
import { GET_ALL_COLLECTIONS } from "../graphql/queries";
import {
  connectWallet,
  getCurrentWalletConnected,
  mintNFT,
} from "../utils/interact.js";
import selected_desktop from "../svg/selected_desktop.svg";
import non_selected_desktop from "../svg/non_selected_desktop.svg";
import selected_mob from "../svg/selected_mob.svg";
import non_selected_mob from "../svg/non_selected_mob.svg";
import menu_divider from "../svg/menu_divider.svg";
import { Tune } from "@material-ui/icons";

const MarketPage = ({userID}) => {

  // const { user } = useAuthContext();
  const { data, loading } = useQuery(GET_ALL_COLLECTIONS, {
    onError: (err) => {
      notify(getErrorMsg(err), "error");
    },
  });
  const classes = useMarketPageStyles();
  const { notify } = useStateContext();
  const [sdesktop, setSDesktop] = useState(true);
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");
  const [filterInput, setFilterInput] = useState("");

  return (
    <>
  <div className={classes.menuBar}>
    <img
      style={{ cursor: "pointer" }}
      typeof="button"
      height="30px"
      width="30px"
      src={sdesktop ? selected_desktop : non_selected_desktop}
      onClick={() => setSDesktop(true)}
    />
    <img
      style={{ cursor: "pointer" }}
      typeof="button"
      height="30px"
      width="30px"
      src={menu_divider}
    />
    <img
      style={{ cursor: "pointer" }}
      typeof="button"
      height="30px"
      width="30px"
      src={!sdesktop ? selected_mob : non_selected_mob}
      onClick={() => setSDesktop(false)}
    />
  </div>

  <div className={classes.root}>
    {!loading && data && !userID ? (
      <div className={classes.nftList}>
        {data.getAllCollections.map((u) => (
          <div
            key={u.id}
            className={sdesktop ? classes.userNFT : classes.userNFTMob}
          >
            <Chip
              label={`Title : ${u.title}`}
              variant="outlined"
              color="primary"
              size="small"
              className={classes.tag}
            />
            <Avatar
              src={u.imgLink}
              alt={u.username}
              className={classes.nftCard}
            />
            <div className={classes.cardText}>
              <Link component={RouterLink} to={`/user/${u.mintedBy}`}>
                <Chip
                  label={`Minted By : ${u.mintedBy}`}
                  variant="outlined"
                  color="primary"
                  size="small"
                  className={classes.tag}
                />
              </Link>
            </div>
          </div>
        ))}
      </div>
    ) : (
      <div className={classes.root}>
        {!loading && data && userID ? (
          <div className={classes.nftList}>
            {data.getAllCollections
              .filter((item) => item.mintedBy === userID)
              .map((u) => (
                <div
                  key={u}
                  className={sdesktop ? classes.userNFT : classes.userNFTMob}
                >
                  <Chip
                    label={`Title : ${u.title}`}
                    variant="outlined"
                    color="primary"
                    size="small"
                    className={classes.tag}
                  />
                  <Avatar
                    src={u.imgLink}
                    alt={u.username}
                    className={classes.nftCard}
                  />
                  <div className={classes.cardText}>
                    <Link component={RouterLink} to={`/user/${u.mintedBy}`}>
                      <Chip
                        label={`Minted By : ${u.mintedBy}`}
                        variant="outlined"
                        color="primary"
                        size="small"
                        className={classes.tag}
                      />
                    </Link>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div style={{ minWidth: "100%" }}>
            <LoadingSpinner size={80} />
          </div>
        )}
      </div>
    )}
  </div>
</>
  );
};

export default MarketPage;
