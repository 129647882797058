import { ButtonGroup, Button } from "@material-ui/core";
import { useQuesListStyles } from "../styles/muiStyles";
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

const ProfileTabBar = ({ isMobile, changeBy, setChangeBy }) => {
  const theme = useTheme();
  const classes = useQuesListStyles();

  const handleChange = (e) => {
    setChangeBy(e.target.innerText);
  };

  return (
    <div className={classes.btnGroupWrapper}>
      <ButtonGroup
        color="primary"
        disableElevation
        size={isMobile ? "small" : "medium"}
        // fullWidth={isMobile}
      >
        <Button
          variant={changeBy === "Profile" ? "contained" : "outlined"}
          onClick={handleChange}
        >
          Profile
        </Button>
        <Button
          variant={changeBy === "Questions" ? "contained" : "outlined"}
          onClick={handleChange}
        >
          Questions
        </Button>
        <Button
          variant={changeBy === "NFT" ? "contained" : "outlined"}
          onClick={handleChange}
        >
          NFT
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default ProfileTabBar;
