import { makeStyles } from "@material-ui/core/styles";

export const useBodyStyles = makeStyles(
  (theme) => ({
    root: {
      // width: "100vW",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      flex: 1,
      minHeight: "100vH",
      background: "rgba(28, 28, 28, 1)",
    },
  }),
  { index: 1 }
);

export const useNavStyles = makeStyles(
  (theme) => ({
    leftPortion: {
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
    },
    navbarLogoContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRight: "2px solid",
      borderImageSlice: 1,
      borderImageSource: "linear-gradient( #003638 100%, #72C974 100%)",
      [theme.breakpoints.down("xs")]: {
        border: "none",
        justifyContent: "flex-start",
      },
    },
    navbarMidContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      borderBottom: "2px solid",
      borderImageSlice: 1,
      borderImageSource: "linear-gradient(91.61deg, #003638 0%, #72C974 100%)",
      [theme.breakpoints.down("xs")]: {
        border: "none",
        flexWrap: "nowrap",
      },
    },
    navbarMid: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      columnGap: "15px",
    },
    logoWrapper: {
      marginRight: "1em",
      display: "flex",
      justifyContent: "center",
      width: "14.3em",
      [theme.breakpoints.down("xs")]: {
        width: "auto",
        border: "none",
        background: "red",
        margin: "0",
      },
    },
    logo: {
      textTransform: "none",
      padding: "0.1em",
      marginLeft: "0.01em",
      borderRadius: 0,
      [theme.breakpoints.down("xs")]: {
        borderRight: 0,
        // background: "red",
        padding: 0,
      },
    },
    contentContainer: {
      display: "flex",
      alignItems: "center",
      maxWidth: "100%",
    },
    appBar: {
      background: "rgba(28, 28, 28, 1)",
      boxShadow: "none",
    },
    searchBar: {
      flexGrow: 0.2,

      [theme.breakpoints.down("sm")]: {
        marginLeft: "0.2em",
        marginRight: "0.2em",
      },

      [theme.breakpoints.down("xs")]: {
        flexGrow: 1,
        marginLeft: "0.6em",
        marginRight: "0.6em",
      },

      "& .MuiOutlinedInput-root": {
        borderRadius: "15px ",
      },
    },
    askQuesBtn: {
      marginLeft: "5em",
      color: "#ffffff",
      borderRadius: "50px",
      display: "flex",
      fontFamily: "Lexend",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "16px",
      background: theme.palette.primary.own,

      [theme.breakpoints.down("md")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "2em",
        fontSize: "10px",
        backgroundColor: "red",
      },
      [theme.breakpoints.down("xs")]: {
        margin: "0 auto",
      },
    },
    searchBtn: {
      padding: "0em",
    },
    iconBtn: {
      [theme.breakpoints.down("xs")]: {
        padding: "0.2em",
      },
    },
  }),
  { index: 1 }
);

export const useMenuStyles = makeStyles(
  (theme) => ({
    menuIcon: {
      marginRight: "6px",
      fontSize: "1.3em",
    },
    svgImage: {
      objectFit: "contain",
      width: "20px",
    },
    menuItem: {
      [theme.breakpoints.down("1280")]: {
        padding: "6px 4px",
      },
      [theme.breakpoints.down("1200")]: {
        padding: "6px",
      },
    },
    closeIcon: {
      boxSizing: "border-box",
      border: "0.5px solid ",
      borderRadius: 3,
    },
    rootPanel: {
      position: "sticky",
      width: "100%",
      top: "5.5vH",
      minHeight: "94.5vh",
      display: "flex",
      "& .MuiMenuItem-root": {
        minHeight: "0",
      },
      // [theme.breakpoints.down("sm")]: {
      //   width: "10.2em",
      // },
    },
    list: {
      marginTop: "5em",
      // "& .MuiMenuItem-root": {
      //   minHeight: '0',
      // },
      "& .Mui-selected": {
        fontWeight: "700",
        border: "none",
      },
    },
    madeByItem: {
      padding: "0.4em 0.8em 0.2em 0.8em",
    },
    userBtn: {
      textTransform: "none",
      display: "flex",
    },
    avatar: {
      width: theme.spacing(3.5),
      height: theme.spacing(3.5),
      marginRight: "0.4em",
      borderRadius: 2,
      [theme.breakpoints.down("900")]: {
        marginRight: 0,
      },
      [theme.breakpoints.down("xs")]: {
        // marginRight: "0.2em",
        marginRight: 0,
        width: theme.spacing(2.8),
        height: theme.spacing(2.8),
      },
    },
    moreBtn: {
      padding: 0,
    },
    userBtnMob: {
      padding: "0.3em",
      [theme.breakpoints.down("900")]: {
        padding: 0,
      },
    },
  }),
  { index: 1 }
);

export const useQuesListStyles = makeStyles(
  (theme) => ({
    root: {
      // padding: "0.4em 0.7em",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "0.3em",
        paddingRight: "0.3em",
        border: "none",
      },
    },
    topBar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      flexWrap: "wrap",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "0.4em",
        paddingRight: "0.4em",
      },
    },
    btnGroupWrapper: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      margin: "1em 0",
      border: "none",

      "& .MuiButton-containedPrimary": {
        background: "#000000",
        background:
          "-webkit-linear-gradient(91.61deg, #003638 0%, #72C974 100%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        borderBottom: "1px solid",
        fontSize: "16px",
        borderImageSlice: 1,
        borderImageSource:
          "linear-gradient(91.61deg, #003638 0%, #72C974 100%)",
        width: "130px",
      },
      "& .MuiButton-outlinedPrimary": {
        color: "#ffffff",
        border: "none",
        width: "130px",
        fontSize: "16px",
      },
    },
    loadBtnWrapper: {
      display: "flex",
      justifyContent: "center",
    },
    loadBtn: {
      marginTop: "0.8em",
      marginBottom: "0.4em",
      width: "50%",
      display: "flex",
    },
    noQuesText: {
      textAlign: "center",
      marginTop: "2em",
    },
  }),
  { index: 1 }
);

export const useRightSidePanelStyles = makeStyles(
  (theme) => ({
    rootPanel: {
      position: "sticky",
      // top: "5.5vh",
      display: "flex",
      background: "#0D0D0D",
      minHeight: "10vh",
      width: "100%",
      margin: "0 auto",
    },
    content: {
      paddingTop: 0,
      width: "90%",
    },
    tagsColumn: {
      marginTop: "2em",
      border: "2px solid",
      borderImageSlice: 1,
      borderImageSource: "linear-gradient(91.6deg, #003638 0%, #72C974 70%)",
      borderRadius: 4,
      padding: "0.8em",
      backgroundColor: `${theme.palette.primary.main}08`,
      [theme.breakpoints.down("1010")]: {
        padding: "0.8em 0.2em",
      },
    },
    // tag : {
    //   background: "red",
    // },
    tagsWrapper: {
      marginTop: "1em",
      display: "grid",
      width: "100%",
      // gridTemplateColumns: "repeat(2, minmax(130px, 1fr))",
      gridTemplateColumns: "repeat(2, 1fr)",
      gridGap: "8px",
    },
  }),
  { index: 1 }
);

export const useQuesCardStyles = makeStyles(
  (theme) => ({
    root: {
      borderRadius: "5px",
      marginBottom: "1em",
      display: "flex",
      padding: "0.5em 0",
      paddingRight: "1em",
      margin: "0 auto",
      width: "60%",
      background: "rgba(255, 255, 255, 0.1)",
      [theme.breakpoints.down("1280")]: {
        width: "80%",
      },
      [theme.breakpoints.down("900")]: {
        width: "90%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "90%",
      },
    },
    infoWrapper: {
      width: "10%",
      padding: "0.4em",
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        width: "22%",
        paddingRight: "0.2em",
      },
    },
    mainText: {
      fontSize: "1.2em",
      fontWeight: 500,
    },
    title: {
      fontSize: "1.2em",
      fontWeight: 500,
      wordWrap: "anywhere",
      textDecoration: "none",
      "&:hover": {
        filter: "brightness(200%)",
      },
      transition: "all 0.4s ease",
    },
    innerInfo: {
      display: "flex",
      flexDirection: "column",
    },
    quesDetails: {
      paddingLeft: "0.2em",
      paddingBottom: "0.7em",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "100%",
    },
    tagsWrapper: {
      display: "flex",
      flexWrap: "wrap",
    },
    tag: {
      marginRight: "0.5em",
      marginTop: "0.5em",
    },
    bottomWrapper: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    byUserWrapper: {
      display: "flex",
      alignItems: "center",
      marginTop: "0.7em",
    },
    filledByUser: {
      display: "flex",
      alignItems: "center",
      marginTop: "0.7em",
      backgroundColor: `${theme.palette.primary.main}10`,
      padding: "0.4em",
      borderRadius: 3,
      border: `1px solid ${theme.palette.primary.main}40`,
    },
    homeAvatar: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      marginRight: "0.4em",
      borderRadius: 2,
    },
    quesAnsAvatar: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      marginRight: "0.4em",
      borderRadius: 2,
    },
  }),
  { index: 1 }
);

export const useDialogStyles = makeStyles(
  (theme) => ({
    dialogWrapper: {
      padding: 0,
      overflow: "hidden",
      [theme.breakpoints.down("400")]: {
        padding: "0 !important",
        margin: "0 !important",
        maxHeight: "100% !important",
        height: "100%",
        borderRadius: "0 !important",
      },
    },
    menuIcon: {
      marginRight: "6px",
      fontSize: "1.3em",
    },
    upDownIcon: {
      color: "#aa9aaa",
      fontSize: 32,
    },
  }),
  { index: 1 }
);

export const useAuthFormStyles = makeStyles(
  (theme) => ({
    root: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 20,
      [theme.breakpoints.down("xs")]: {
        padding: "0 0 0 0",
      },
    },
    welcomeTxt: {
      textAlign: "left",
      fontWeight: "bold",
      [theme.breakpoints.down("450")]: {
        textAlign: "center",
        fontSize: "2.2rem",
      },
      [theme.breakpoints.down("325")]: {
        fontSize: "2rem",
      },
    },
    boxContainer: {
      padding: "32px",
      [theme.breakpoints.down("325")]: {
        padding: "32px 16px",
      },
    },
    inputField: {
      marginBottom: "1.5em",
    },
    submitButton: {
      marginTop: "1.8em",
    },
    titleLogo: {
      display: "block",
      width: "5em",
      margin: "0 auto 2em auto",
    },
    footerText: {
      marginTop: "1em",
      textAlign: "center",
    },
    link: {
      cursor: "pointer",
      textDecoration: "underline",
    },
    button: {
      background: theme.palette.primary.own,
      marginTop: "2rem",
    },
    onboard: {
      width: "311px",
      height: "50px",
      margin: "0 auto",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "40px",
      lineHeight: "50px",
      color: "#FFFFFF",
      // paddingBottom: '3em',
      marginTop: "1em",
    },
    desc: {
      width: "352px",
      height: "46px",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "22px",
      color: "#C1C1C1",
      margin: "0 auto",
      marginTop: "4em",
      marginBottom: "4em",
    },
  }),
  { index: 1 }
);

export const useRewardPageStyle = makeStyles(
  (theme) => ({
    root: {
      padding: "0.4em 0.7em",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "0.3em",
        paddingRight: "0.3em",
        border: "none",
      },
    },
    rewardsMainHead: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "32px",
      lineHeight: "48px",
      textAlign: "center",
      [theme.breakpoints.down("350")]: {
        fontSize: "25px",
      },
    },
    youLlGet: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "20px",
      [theme.breakpoints.down("350")]: {
        fontSize: "17px",
      },
    },
    rewardsHead: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "38px",
      lineHeight: "2px",
      [theme.breakpoints.down("350")]: {
        fontSize: "32px",
      },
    },
    carouselBox: {
      background: "rgba(255, 255, 255, 0.1)",
      width: "290px",
      height: "362px",
      borderRadius: "10px",
      [theme.breakpoints.down("350")]: {
        width: "220px",
        height: "340px",
      },
    },
    carouselImages: {
      width: "250px",
      // height: "250px",
      objectFit: "contain",
      [theme.breakpoints.down("350")]: {
        width: "220px",
      },
    },
    onboard: {
      // width: "260px",
      width: "100%",
      height: "32px",
      margin: "0 auto",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      color: "#FFFFFF",
      marginTop: "1em",
    },
    buttonReward: {
      background: "#FFBC0F",
      width: "76px",
      height: "42px",
      marginBottom: "6em",
      "&.Mui-disabled": {
        color: "#FFFFFF",
        background: "#000000",
      },
      [theme.breakpoints.down("350")]: {
        marginBottom: "4em",
      },
    },
    buttonReputation: {
      background: "#FFBC0F",
      width: "142px",
      height: "42px",
      marginBottom: "6em",

      "&.Mui-disabled": {
        color: "#FFFFFF",
      },
    },
    desc: {
      width: "352px",
      height: "46px",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "22px",
      color: "#C1C1C1",
      margin: "0 auto",
      marginTop: "4em",
      marginBottom: "4em",
    },
  }),
  { index: 1 }
);

export const useTagsPageStyles = makeStyles(
  (theme) => ({
    root: {
      marginTop: "1em",
      padding: "0.4em 0.7em",
      // width: "100%",
    },
    titleText: {
      marginBottom: "0.9em",
    },
    filterInput: {
      marginTop: "1.2em",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    tagsWrapper: {
      marginTop: "1em",
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, minmax(157px, 1fr))",
      gridGap: "12px",
    },
    tagBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "0.8em",
      paddingBottom: "0.4em",
      border: "1px solid #d3d3d3",
      borderRadius: 4,
    },
    tag: {
      marginBottom: "0.9em",
    },
  }),
  { index: 1 }
);

export const useUsersPageStyles = makeStyles(
  (theme) => ({
    root: {
      marginTop: "1em",
      padding: "0.4em 0.7em",
      width: "100%",
    },
    filterInput: {
      marginTop: "1.2em",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    usersWrapper: {
      marginTop: "1.4em",
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
      gridGap: "14px",
    },
    userBox: {
      display: "flex",
      alignItems: "center",
    },
    avatar: {
      width: theme.spacing(6),
      height: theme.spacing(6),
      marginRight: "0.6em",
      borderRadius: 2,
    },
  }),
  { index: 1 }
);

export const useQuesPageStyles = makeStyles(
  (theme) => ({
    root: {
      width: "100%",
      paddingLeft: "0.7em",
      marginTop: "1em",
      [theme.breakpoints.down("900")]: {
        padding: "0 0.7em",
      },
    },
    titleWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      paddingBottom: "0.4em",
      [theme.breakpoints.down("xs")]: {
        flexWrap: "wrap",
      },
    },
    contentContainer: {
      flexWrap: "nowrap !important",
      justifyContent: "space-around",
      gap: "5rem",
      [theme.breakpoints.down("1280")]: {
        gap: "3.5rem",
      },
      [theme.breakpoints.down("900")]: {
        justifyContent: "flex-start",
      },
    },
    quesInfo: {
      display: "flex",
      paddingBottom: "0.8em",
    },
    content: {
      paddingTop: "0.5em",
      width: "100%",
      paddingBottom: "1em",
    },
    quesAnsWrapper: {
      display: "flex",
      marginBottom: "1em",
    },
    voteColumn: {
      display: "flex",
      flexDirection: "column",
      width: 30,
      alignItems: "center",
    },
    quesBody: {
      padding: "0.6em 1em",
      paddingBottom: 0,
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        paddingRight: "0",
      },
    },
    tag: {
      marginRight: "0.5em",
      marginTop: "0.5em",
    },
    tagsWrapper: {
      display: "flex",
      flexWrap: "wrap",
      marginTop: "1em",
    },
    bottomWrapper: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      marginTop: "1.4em",
      marginBottom: "0.8em",
    },
    bottomBtns: {
      padding: "0.15em",
    },
    commentWrapper: {
      padding: "0.5em 0.2em",
    },
    commentBtns: {
      padding: "0 0.1em",
      minWidth: "3em",
    },
    smallForm: {
      marginTop: "1em",
    },
    submitCancelBtns: {
      display: "flex",
      justifyContent: "flex-start",
      marginTop: "0.3em",
    },
    answersWrapper: {
      marginTop: "1em",
    },
    answerHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "0.5em",
      padding: "0 0.5em",
    },
    acceptIcon: {
      color: "#aa9aaa",
      fontSize: 32,
    },
    checkedAcceptIcon: {
      color: "#2e8b57",
      fontSize: 32,
    },
    answerWrapper: {
      marginBottom: "0.5em",
    },
    answerForm: {
      marginTop: "2em",
    },
    footerText: {
      marginTop: "1em",
      marginBottom: "1em",
      display: "flex",
      alignItems: "flex-start",
    },
    footerTag: {
      marginRight: "0.5em",
    },
  }),
  { index: 1 }
);

export const useLeaderPageStyles = makeStyles(
  (theme) => ({
    container: {
      // height: "100vh",
      overflow: "auto",
      justifyContent: "center",
    },
    header: {
      backgroundColor: theme.palette.background.default,
      position: "sticky",
      top: 0,
      zIndex: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    leaderMain: {
      justifyContent: "center",
      position: "sticky",
      top: 0,
      background: "#0d0d0d",
      [theme.breakpoints.down("xs")]: {
        position: "unset",
      },
    },
    leaderboardContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    leaderboardItemContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    leaderpositionItem: {
      background: "none",
      marginTop: "2em",
      alignItems: "center",
      marginBottom: theme.spacing(2),
      "&:last-child": {
        marginBottom: 0,
      },
    },
    leaderboardItem: {
      background:
        "linear-gradient(91.61deg, rgba(0, 54, 56, 0.1) 0%, rgba(114, 201, 116, 0.1) 100%)",
      display: "flex",
      borderRadius: "16px",
      alignItems: "center",
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
      borderRadius: 8,
      "&:last-child": {
        marginBottom: 0,
      },
    },
    first: {
      backgroundColor: "#fffde8",
    },
    second: {
      // backgroundColor: "#f9f9f9",
    },
    third: {
      backgroundColor: "#fffbf2",
    },
    ranks: {
      borderRadius: "12px",
      border: "4px solid",
      borderImageSlice: 1,

      "& .topPosName": {
        [theme.breakpoints.down("480")]: {
          fontSize: 14,
        },
        [theme.breakpoints.down("410")]: {
          fontSize: 12,
        },
        [theme.breakpoints.down("354")]: {
          fontSize: 10,
        },
        [theme.breakpoints.down("300")]: {
          fontSize: 9,
        },
      },

      "& .topPosPoints": {
        [theme.breakpoints.down("480")]: {
          fontSize: 12,
        },
        [theme.breakpoints.down("410")]: {
          fontSize: 10,
        },
        [theme.breakpoints.down("354")]: {
          fontSize: 9,
        },
        [theme.breakpoints.down("300")]: {
          fontSize: 8,
        },
      },
    },
    img: {
      marginRight: theme.spacing(2),
      width: "98px",
      height: "98px",
    },
    imgLeader: {
      // width: "98px",
      // height: "98px",
    },
    imgBadge: {
      width: "100%",
      height: "100px",
      position: "relative",
      top: "-5%",
      left: "26%",
      transform: "translate(-26%, 5%)",
      objectFit: "contain",
      [theme.breakpoints.down("900")]: {
        height: "70px",
      },
      [theme.breakpoints.down("700")]: {
        height: "60px",
      },
      [theme.breakpoints.down("360")]: {
        height: "45px",
      },
    },
    name: {
      fontWeight: "bold",
      fontSize: 16,
      marginBottom: theme.spacing(1),
    },
    points: {
      color: theme.palette.text.secondary,
      fontSize: 14,
      // [theme.breakpoints.down("480")]: {
      //   fontSize: 12,
      // },
      // [theme.breakpoints.down("410")]: {
      //   fontSize: 10,
      // },
      // [theme.breakpoints.down("354")]: {
      //   fontSize: 9,
      // },
      // [theme.breakpoints.down("300")]: {
      //   fontSize: 8,
      // },
    },
    btn_container: {
      display: "block",
      textDecoration: "none",
      marginBottom: theme.spacing(2),
    },
    ques_container: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(2),
      borderRadius: 8,
      backgroundColor: theme.palette.background.paper,
    },
    name_points_position: {
      flexGrow: 1,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    position: {
      // minWidth: 50,
    },
  }),
  { index: 1 }
);
export const useMintPageStyles = makeStyles(
  (theme) => ({
    root: {
      marginTop: "1em",
      padding: "0em 1.4em",
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        padding: "0em 0.9em",
        flexDirection: "column",
      },
    },
    userCard: {
      backgroundColor: `${theme.palette.primary.main}15`,
      padding: "1.6em",
      height: "18.5em",
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "16em",
        marginBottom: "0.5em",
      },
    },
    userNFT: {
      backgroundColor: `${theme.palette.primary.main}15`,
      padding: "1.6em",
      height: "12.5em",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: "1em",
      [theme.breakpoints.down("xs")]: {
        height: "16em",
        marginBottom: "0.5em",
      },
    },
    userReward: {
      backgroundColor: `${theme.palette.primary.main}15`,
      padding: "1.6em",
      width: theme.spacing(21),
      height: theme.spacing(30),
      textAlign: "center",
      marginTop: "0.5em",
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "12.5em",
        marginBottom: "0.5em",
      },
    },
    avatar: {
      width: theme.spacing("21"),
      height: theme.spacing(21),
      borderRadius: 3,
      [theme.breakpoints.down("xs")]: {
        width: theme.spacing(15),
        height: theme.spacing(15),
      },
    },
    nft: {
      width: theme.spacing("30"),
      height: theme.spacing("30"),
      [theme.breakpoints.down("xs")]: {
        width: theme.spacing(15),
        height: theme.spacing(15),
      },
    },
    nftCard: {
      width: theme.spacing("10"),
      height: theme.spacing(21),
      borderRadius: 3,
      [theme.breakpoints.down("xs")]: {
        width: theme.spacing(15),
        height: theme.spacing(15),
      },
    },
    cardText: {
      marginTop: "0.5em",
    },
    infoCard: {
      paddingLeft: "2em",
      paddingRight: "2em",
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    userInfo: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },
    bigText: {
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.5em",
      },
    },
    smallText: {
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.8em",
      },
    },
    statsBar: {
      textAlign: "center",
      display: "flex",
      alignItems: "center",
    },
    recentActivity: {
      marginTop: "1em",
    },
    recentQuesAns: {
      display: "flex",
      padding: "0.5em 0",
      alignItems: "center",
      justifyContent: "space-between",
    },
    votesTitleWrapper: {
      display: "flex",
      alignItems: "center",
    },
    votes: {
      padding: "0.2em 0.5em",
      border: "1px solid #d3d3d3",
      marginRight: "0.7em",
      [theme.breakpoints.down("xs")]: {
        marginRight: "0.5em",
      },
    },
    title: {
      textDecoration: "none",
      "&:hover": {
        filter: "brightness(200%)",
      },
      transition: "all 0.4s ease",
    },
  }),
  { index: 1 }
);
export const useUserPageStyles = makeStyles(
  (theme) => ({
    root: {
      marginTop: "1em",
      padding: "0em 1.4em",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("xs")]: {
        padding: "0em 0.9em",
      },
    },
    userCard: {
      backgroundColor: `${theme.palette.primary.main}15`,
      padding: "1.6em",
      height: "18.5em",
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "16em",
        marginBottom: "0.5em",
      },
    },
    userNFT: {
      backgroundColor: `${theme.palette.primary.main}15`,
      padding: "1.6em",
      height: "12.5em",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: "1em",
      [theme.breakpoints.down("xs")]: {
        height: "16em",
        marginBottom: "0.5em",
      },
    },
    userReward: {
      backgroundColor: `${theme.palette.primary.main}15`,
      padding: "1.6em",
      width: theme.spacing(21),
      height: theme.spacing(30),
      textAlign: "center",
      marginTop: "0.5em",
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "12.5em",
        marginBottom: "0.5em",
      },
    },
    avatar: {
      width: theme.spacing("21"),
      height: theme.spacing(21),
      borderRadius: 3,
      [theme.breakpoints.down("xs")]: {
        width: theme.spacing(15),
        height: theme.spacing(15),
      },
    },
    nft: {
      width: theme.spacing("30"),
      height: theme.spacing("30"),
      [theme.breakpoints.down("xs")]: {
        width: theme.spacing(15),
        height: theme.spacing(15),
      },
    },
    nftCard: {
      width: theme.spacing("10"),
      height: theme.spacing(21),
      borderRadius: 3,
      [theme.breakpoints.down("xs")]: {
        width: theme.spacing(15),
        height: theme.spacing(15),
      },
    },
    cardText: {
      marginTop: "0.5em",
    },
    infoCard: {
      paddingLeft: "2em",
      paddingRight: "2em",
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    userInfo: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },
    bigText: {
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.5em",
      },
    },
    smallText: {
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.8em",
      },
    },
    statsBar: {
      textAlign: "center",
      display: "flex",
      alignItems: "center",
    },
    recentActivity: {
      marginTop: "1em",
    },
    recentQuesAns: {
      display: "flex",
      padding: "0.5em 0",
      alignItems: "center",
      justifyContent: "space-between",
    },
    votesTitleWrapper: {
      display: "flex",
      alignItems: "center",
    },
    votes: {
      padding: "0.2em 0.5em",
      border: "1px solid #d3d3d3",
      marginRight: "0.7em",
      [theme.breakpoints.down("xs")]: {
        marginRight: "0.5em",
      },
    },
    title: {
      textDecoration: "none",
      "&:hover": {
        filter: "brightness(200%)",
      },
      transition: "all 0.4s ease",
    },
  }),
  { index: 1 }
);

export const useMarketPageStyles = makeStyles(
  (theme) => ({
    root: {
      marginTop: "1em",
      padding: "0em 1.4em",

      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        padding: "0em 0.9em",
      },
    },
    menuBar: {
      marginTop: "1em",
      padding: "0em 1.4em",
      display: "flex",
      justifyContent: "center",
      // [theme.breakpoints.down("sm")]: {
      //   flexDirection: "column",
      //   padding: "0em 0.9em",
      // },
      [theme.breakpoints.down("850")]: {
        display: "none",
      },
    },
    nftList: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
    },
    userCard: {
      backgroundColor: `${theme.palette.primary.main}15`,
      padding: "1.6em",
      height: "18.5em",
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "16em",
        marginBottom: "0.5em",
      },
    },
    userNFT: {
      border: "2px solid",
      borderRadius: "20px",
      borderImageSlice: 1,
      borderImageSource: "linear-gradient(91.6deg,#003638 0%, #72C974 70%)",
      padding: "1.6em",
      width: "100%",
      // height: "12.5em",
      textAlign: "center",
      display: "flex",
      flex: "0 0 30%",
      flexDirection: "column",
      justifyItems: "center",
      alignItems: "center",
      margin: "1em",
      [theme.breakpoints.down("850")]: {
        // height: "16em",
        flex: "0 0 60%",
        marginBottom: "0.5em",
      },
    },
    userNFTMob: {
      border: "2px solid",
      borderRadius: "20px",
      borderImageSlice: 1,
      borderImageSource: "linear-gradient(91.6deg,#003638 0%, #72C974 70%)",
      padding: "1.6em",
      width: "50px",
      // height: "12.5em",
      textAlign: "center",
      display: "flex",
      flex: "0 50%",
      flexDirection: "column",
      justifyItems: "center",
      alignItems: "center",
      margin: "1em",
      [theme.breakpoints.down("xs")]: {
        // height: "16em",
        flex: "0 0 60%",
        marginBottom: "0.5em",
      },
    },
    userReward: {
      backgroundColor: `${theme.palette.primary.main}15`,
      padding: "1.6em",
      width: theme.spacing(21),
      height: theme.spacing(30),
      textAlign: "center",
      marginTop: "0.5em",
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "12.5em",
        marginBottom: "0.5em",
      },
    },
    avatar: {
      width: theme.spacing("21"),
      height: theme.spacing(21),
      borderRadius: 3,
      [theme.breakpoints.down("xs")]: {
        width: theme.spacing(15),
        height: theme.spacing(15),
      },
    },
    nft: {
      width: theme.spacing("30"),
      height: theme.spacing("30"),
      [theme.breakpoints.down("xs")]: {
        width: theme.spacing(15),
        height: theme.spacing(15),
      },
    },
    nftCard: {
      width: theme.spacing("10"),
      height: theme.spacing(21),
      borderRadius: 3,
      [theme.breakpoints.down("xs")]: {
        width: theme.spacing(15),
        height: theme.spacing(15),
      },
    },
    cardText: {
      marginTop: "0.5em",
    },
    infoCard: {
      paddingLeft: "2em",
      paddingRight: "2em",
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    userInfo: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },
    bigText: {
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.5em",
      },
    },
    smallText: {
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.8em",
      },
    },
    statsBar: {
      textAlign: "center",
      display: "flex",
      alignItems: "center",
    },
    recentActivity: {
      marginTop: "1em",
    },
    recentQuesAns: {
      display: "flex",
      padding: "0.5em 0",
      alignItems: "center",
      justifyContent: "space-between",
    },
    votesTitleWrapper: {
      display: "flex",
      alignItems: "center",
    },
    votes: {
      padding: "0.2em 0.5em",
      border: "1px solid #d3d3d3",
      marginRight: "0.7em",
      [theme.breakpoints.down("xs")]: {
        marginRight: "0.5em",
      },
    },
    title: {
      textDecoration: "none",
      "&:hover": {
        filter: "brightness(200%)",
      },
      transition: "all 0.4s ease",
    },
  }),
  { index: 1 }
);

export const useAskQuesPageStyles = makeStyles(
  (theme) => ({
    root: {
      width: "100%",
      background: "#0D0D0D",
      height: "100%",
    },
    modalHeading: {
      [theme.breakpoints.down("600")]: {
        fontSize: "1.2rem",
      },
    },
    quesForm: {
      paddingTop: "0.8em",
    },
    inputWrapper: {
      marginBottom: "2em",
    },
    inputField: {
      border: "1px solid",
      borderImageSlice: 1,
      borderImageSource: "linear-gradient(91.61deg, #003638 0%, #72C974 100%)",
      marginTop: "0.4em",

      "& input": {
        [theme.breakpoints.down("600")]: {
          fontSize: "0.8rem",
        },
      },
      "& textarea": {
        [theme.breakpoints.down("600")]: {
          fontSize: "0.8rem",
        },
      },
    },
    submitBtn: {
      width: "100%",
      background: "linear-gradient(91.61deg, #003638 0%, #72C974 100%)",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    tag: {
      marginRight: "0.5em",
    },
    closeBtn: {
      color: "#ffffff",
      padding: "5px 3px",
      position: "absolute",
      right: "0",
    },
  }),
  { index: 1 }
);

export const useVoteBtnsStyles = makeStyles(
  (theme) => ({
    icon: {
      color: "#aa9aaa",
      fontSize: 32,
    },
    checkedIcon: {
      color: "#964ec2",
      fontSize: 32,
    },
  }),
  { index: 1 }
);

export const useAlertStyles = makeStyles(
  (theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
      marginTop: "0.8em",
      marginBottom: "0.8em",
    },
  }),
  { index: 1 }
);

export const useNotFoundPageStyles = makeStyles(
  (theme) => ({
    root: {
      width: "100%",
      marginTop: "1em",
      padding: "0.4em 1em",
    },
    textWrapper: {
      marginTop: "15%",
      textAlign: "center",
    },
    icon: {
      fontSize: "6em",
      marginBottom: "0.3em",
    },
  }),
  { index: 1 }
);
