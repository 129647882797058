export const NFT_ABI = {
	"ABI version": 2,
	"version": "2.2",
	"header": ["pubkey", "time", "expire"],
	"functions": [
		{
			"name": "constructor",
			"inputs": [
				{"name":"codeNft","type":"cell"},
				{"name":"ownerPubkey","type":"uint256"},
				{"name":"json","type":"string"}
			],
			"outputs": [
			]
		},
		{
			"name": "mintNft",
			"inputs": [
				{"name":"json","type":"string"}
			],
			"outputs": [
			]
		},
		{
			"name": "setRemainOnNft",
			"inputs": [
				{"name":"remainOnNft","type":"uint128"}
			],
			"outputs": [
			]
		},
		{
			"name": "owner",
			"inputs": [
				{"name":"answerId","type":"uint32"}
			],
			"outputs": [
				{"name":"pubkey","type":"uint256"}
			]
		},
		{
			"name": "transferOwnership",
			"inputs": [
				{"name":"newOwner","type":"uint256"}
			],
			"outputs": [
			]
		},
		{
			"name": "getJson",
			"inputs": [
				{"name":"answerId","type":"uint32"}
			],
			"outputs": [
				{"name":"json","type":"string"}
			]
		},
		{
			"name": "totalSupply",
			"inputs": [
				{"name":"answerId","type":"uint32"}
			],
			"outputs": [
				{"name":"count","type":"uint128"}
			]
		},
		{
			"name": "nftCode",
			"inputs": [
				{"name":"answerId","type":"uint32"}
			],
			"outputs": [
				{"name":"code","type":"cell"}
			]
		},
		{
			"name": "nftCodeHash",
			"inputs": [
				{"name":"answerId","type":"uint32"}
			],
			"outputs": [
				{"name":"codeHash","type":"uint256"}
			]
		},
		{
			"name": "nftAddress",
			"inputs": [
				{"name":"answerId","type":"uint32"},
				{"name":"id","type":"uint256"}
			],
			"outputs": [
				{"name":"nft","type":"address"}
			]
		},
		{
			"name": "supportsInterface",
			"inputs": [
				{"name":"answerId","type":"uint32"},
				{"name":"interfaceID","type":"uint32"}
			],
			"outputs": [
				{"name":"value0","type":"bool"}
			]
		}
	],
	"data": [
	],
	"events": [
		{
			"name": "OwnershipTransferred",
			"inputs": [
				{"name":"oldOwner","type":"uint256"},
				{"name":"newOwner","type":"uint256"}
			],
			"outputs": [
			]
		},
		{
			"name": "NftCreated",
			"inputs": [
				{"name":"id","type":"uint256"},
				{"name":"nft","type":"address"},
				{"name":"owner","type":"address"},
				{"name":"manager","type":"address"},
				{"name":"creator","type":"address"}
			],
			"outputs": [
			]
		},
		{
			"name": "NftBurned",
			"inputs": [
				{"name":"id","type":"uint256"},
				{"name":"nft","type":"address"},
				{"name":"owner","type":"address"},
				{"name":"manager","type":"address"}
			],
			"outputs": [
			]
		}
	],
	"fields": [
		{"name":"_pubkey","type":"uint256"},
		{"name":"_timestamp","type":"uint64"},
		{"name":"_constructorFlag","type":"bool"},
		{"name":"_supportedInterfaces","type":"optional(cell)"},
		{"name":"_codeNft","type":"cell"},
		{"name":"_totalSupply","type":"uint128"},
		{"name":"_json","type":"string"},
		{"name":"_owner","type":"uint256"},
		{"name":"_remainOnNft","type":"uint128"}
	]
}

// export const COLLECTION_ADDR = "0:d0b1762f747de517475651d64d7fa50cf91abf5f748d859097c2eecf9ae69d13"
export const COLLECTION_ADDR = "0:7d935c943cdea208756bdbcfc3dc0d6bad576f81329c5b44c7e5c7010eabc651"