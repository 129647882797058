import { useState } from "react";
import NavBar from "./components/NavBar";
import ToastNotification from "./components/ToastNotification";
import Routes from "./pages/Routes";
import { useStateContext } from "./context/state";
import customTheme from "./styles/customTheme";
import { useBodyStyles } from "./styles/muiStyles";
import { Paper } from "@material-ui/core/";
import { ThemeProvider } from "@material-ui/core/styles";
import Notification from "./components/Notification";

const App = () => {
  const { darkMode } = useStateContext();
  const classes = useBodyStyles();

  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    setModalOpen(true);
  };

  return (
    <ThemeProvider theme={customTheme(darkMode)}>
      <Notification />
      {/* <Paper className={classes.root} elevation={0}> */}
      <Paper className={classes.root} elevation={0}>
        <ToastNotification />
        <NavBar
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          handleClickOpen={handleClickOpen}
        />
        <Routes
          open={open}
          setOpen={setOpen}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          handleClickOpen={handleClickOpen}
        />
      </Paper>
    </ThemeProvider>
  );
};

export default App;
