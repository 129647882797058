import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { REGISTER_USER, REGISTER_WITH_WALLET_USER } from "../graphql/mutations";
import { useAuthContext } from "../context/auth";
import { useStateContext } from "../context/state";
import ErrorMessage from "./ErrorMessage";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import logoImg from "../assets/logo.png";
import { getErrorMsg } from "../utils/helperFuncs";
import { Carousel } from "react-responsive-carousel";
import {
  TextField,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Chip,
  Link,
  // Grid,
  Box,
  Checkbox,
  useMediaQuery,
} from "@material-ui/core";
import { Grid } from "@mui/material";
import { useAuthFormStyles } from "../styles/muiStyles";
import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";
import EnhancedEncryptionIcon from "@material-ui/icons/EnhancedEncryption";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import onboard1 from "../svg/onboard-1.svg";
import onboard2 from "../svg/onboard-2.svg";
import onboard3 from "../svg/onboard-3.svg";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import WalletIcon from "@material-ui/icons/AccountBalanceWallet";
import ConnectWallet from "./ConnectWallet";
import { useTheme } from "@material-ui/core/styles";
import { Address } from "everscale-inpage-provider";
import { initVenomConnect } from "../venom-connect/configure";

const validationSchema = yup.object({
  account: yup.string(),
  email: yup.string().required("Required"),
  username: yup
    .string()
    .required("Required")
    .max(20, "Must be at most 20 characters")
    .min(3, "Must be at least 3 characters")
    .matches(
      /^[a-zA-Z0-9-_]*$/,
      "Only alphanum, dash & underscore characters are allowed"
    ),
  password: yup
    .string()
    .required("Required")
    .min(6, "Must be at least 6 characters"),
  confirmPassword: yup
    .string()
    .required("Required")
    .min(6, "Must be at least 6 characters"),
});

const RegisterForm = ({ setAuthType, closeModal }) => {
  const [venomConnect, setVenomConnect] = useState();
  const [venomProvider, setVenomProvider] = useState();
  const [address, setAddress] = useState();
  const init = async () => {
    const _venomConnect = await initVenomConnect();
    setVenomConnect(_venomConnect);
  };
  useEffect(() => {
    init();
  }, []);

  const login = async () => {
    if (!venomConnect) return;
    await venomConnect.connect();
  };

  const [showPass, setShowPass] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showConfPass, setShowConfPass] = useState(false);
  const classes = useAuthFormStyles();
  const { setUser } = useAuthContext();
  const { notify } = useStateContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("900"));
  const checkHeight = useMediaQuery("(max-height:775px)");

  const getAddress = async (provider) => {
    const providerState = await provider?.getProviderState?.();
    return providerState?.permissions.accountInteraction?.address.toString();
  };
  // Any interaction with venom-wallet (address fetching is included) needs to be authentificated
  const checkAuth = async (_venomConnect) => {
    const auth = await _venomConnect?.checkAuth();
    if (auth) await getAddress(_venomConnect);
  };

  // This handler will be called after venomConnect.login() action
  // connect method returns provider to interact with wallet, so we just store it in state
  const onConnect = async (provider) => {
    setVenomProvider(provider);
    await onProviderReady(provider);
  };
  const onProviderReady = async (provider) => {
    const venomWalletAddress = provider
      ? await getAddress(provider)
      : undefined;
    setAddress(venomWalletAddress);
  };
  useEffect(() => {
    // connect event handler
    const off = venomConnect?.on("connect", onConnect);
    if (venomConnect) {
      checkAuth(venomConnect);
    }
    // just an empty callback, cuz we don't need it
    return () => {
      off?.();
    };
  }, [venomConnect]);

  const onDisconnect = async () => {
    venomProvider?.disconnect();
    setAddress("");
  };

  const { register, handleSubmit, reset, errors } = useForm({
    mode: "onTouched",
    resolver: yupResolver(validationSchema),
  });

  const [registerUser, { loading }] = useMutation(REGISTER_USER, {
    onError: (err) => {
      setErrorMsg(getErrorMsg(err));
    },
  });

  const [registerWalletUser, { loading: registerWalletLoading }] = useMutation(
    REGISTER_WITH_WALLET_USER,
    {
      onError: (err) => {
        setErrorMsg(getErrorMsg(err));
      },
    }
  );

  const onRegister = ({ email, username, password, confirmPassword }) => {
    if (password !== confirmPassword)
      return setErrorMsg("Both passwords need to match.");
    registerUser({
      variables: { email, username, password },
      update: (_, { data }) => {
        // Assuming 'data.register' now returns more fields including 'email' and 'verified'
        if (data.register.verified) {
          setUser(data.register);
          notify(
            `Welcome, ${data.register.username}! You've successfully registered.`
          );
          reset();
          closeModal();
        } else {
          // Handle OTP verification here
          // Show OTP input field to the user and let them enter the received OTP
          // Then call a separate function to handle OTP verification
          // Once OTP is verified, proceed to setUser, notify, reset, and closeModal
          notify(`${data.register.message}`);
          setAuthType("verify");
          // setErrorMsg(`Failed, ${data.register.username}! email not verified.`);
        }
      },
    });
  };

  const onWalletRegister = ({ address }) => {
    if (!address) {
      return setErrorMsg("Connect your wallet");
    } else {
      console.log(address);
      registerWalletUser({
        variables: { account: address },
        update: (_, { data }) => {
          setUser(data.registerWithWallet);
          notify(
            `Welcome, ${data.registerWithWallet.username}! You've successfully registered.`
          );
          reset();
          closeModal();
        },
      });
    }
  };

  const renderIndicator = (onClickHandler, isSelected, index, label) => {
    // Customize the CSS of the indicator dots here
    const dotColor = isSelected ? "#A6E3A7" : "#ffffff";
    const dotStyle = {
      backgroundColor: dotColor,
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      margin: "0 5px",
      display: "inline-block",
      cursor: "pointer",
    };

    return (
      <li
        style={dotStyle}
        onClick={onClickHandler}
        key={index}
        role="button"
        aria-label={label}
        tabIndex={0}
      />
    );
  };

  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        height:
          checkHeight && isMobile ? "100%" : checkHeight ? undefined : "100%",
      }}
    >
      <Grid
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        sx={{ backgroundColor: "rgba(28, 28, 28, 1)" }}
      >
        <form onSubmit={handleSubmit(onRegister)}>
          <Box
            // p={4}
            borderRadius={4}
            maxWidth="400px"
            mx="auto"
            textAlign="center"
            className={classes.boxContainer}
          >
            <img src={logoImg} alt="Logo" />
            <Typography
              variant="h3"
              className={classes.welcomeTxt}
              // style={{ textAlign: "left", fontWeight: "bold" }}
            >
              Create Account
            </Typography>
            <Box mt={2} mb={4}>
              {/* <TextField
              required
              fullWidth
              inputRef={register}
              name="account"
              type="text"
              label="Wallet Account"
              variant="standard"
              size="small"
              value={address}
              error={"account" in errors}
              helperText={"account" in errors ? errors.username.message : ""}
              InputLabelProps={{ required: false }}
            /> */}
              <div className={classes.inputField}>
                <TextField
                  required
                  fullWidth
                  inputRef={register}
                  name="email"
                  type="email"
                  label="Email"
                  variant="outlined"
                  size="small"
                  error={"email" in errors}
                  // helperText={
                  //   "username" in errors ? errors.username.message : ""
                  // }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className={classes.inputField}>
                <TextField
                  required
                  fullWidth
                  inputRef={register}
                  name="username"
                  type="text"
                  label="Username"
                  variant="outlined"
                  size="small"
                  error={"username" in errors}
                  helperText={
                    "username" in errors ? errors.username.message : ""
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className={classes.inputField}>
                <TextField
                  required
                  fullWidth
                  inputRef={register}
                  name="password"
                  type={showPass ? "text" : "password"}
                  label="Password"
                  variant="outlined"
                  size="small"
                  error={"password" in errors}
                  helperText={
                    "password" in errors ? errors.password.message : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPass((prevState) => !prevState)}
                          size="small"
                        >
                          {showPass ? (
                            <VisibilityOffIcon color="secondary" />
                          ) : (
                            <VisibilityIcon color="secondary" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className={classes.inputField}>
                <TextField
                  required
                  fullWidth
                  inputRef={register}
                  name="confirmPassword"
                  type={showConfPass ? "text" : "password"}
                  label="Confirm Password"
                  variant="outlined"
                  size="small"
                  error={"confirmPassword" in errors}
                  helperText={
                    "confirmPassword" in errors
                      ? errors.confirmPassword.message
                      : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setShowConfPass((prevState) => !prevState)
                          }
                          size="small"
                        >
                          {showConfPass ? (
                            <VisibilityOffIcon color="secondary" />
                          ) : (
                            <VisibilityIcon color="secondary" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    startAdornment: (
                      <InputAdornment position="start">
                        <EnhancedEncryptionIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Box>
            <Button
              color="primary"
              variant="contained"
              size="large"
              fullWidth
              startIcon={<PersonAddIcon />}
              type="submit"
              disabled={loading}
            >
              Sign Up
            </Button>
            <div>
              {address ? (
                <>
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    startIcon={<WalletIcon />}
                    onClick={onDisconnect}
                    style={{
                      background: "#D30000",
                      marginBottom: "1em",
                    }}
                  >
                    Disconnect Venom Wallet
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    fullWidth
                    startIcon={<PersonAddIcon />}
                    onClick={() => onWalletRegister({ address })}
                    style={{
                      background:
                        "linear-gradient(91.61deg, #003638 0%, #72C974 100%)",
                    }}
                    disabled={registerWalletLoading}
                  >
                    Sign Up With Wallet
                  </Button>
                </>
              ) : (
                ""
              )}
            </div>
            {!address ? (
              <Button
                className={classes.button}
                variant="contained"
                size="large"
                fullWidth
                startIcon={<WalletIcon />}
                disabled={loading}
                onClick={login}
              >
                Connect Venom Wallet
              </Button>
            ) : (
              ""
            )}
            <Box
              display="flex"
              flexDirection={"column"}
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <Typography variant="body1" className={classes.footerText}>
                  Already have an account?{" "}
                  <Link
                    onClick={() => setAuthType("login")}
                    className={classes.link}
                  >
                    Login
                  </Link>
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Typography variant="body1" className={classes.footerText}>
                  Or Verify your email{" "}
                  <Link
                    onClick={() => setAuthType("verify")}
                    className={classes.link}
                  >
                    Verify
                  </Link>
                </Typography>
              </Box>
            </Box>
            <ErrorMessage
              errorMsg={errorMsg}
              clearErrorMsg={() => setErrorMsg(null)}
            />
          </Box>
        </form>
      </Grid>
      {!isMobile && (
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          className="hidden sm:block"
          style={{
            // display: isMobile ? "none" : "",
            background: "#0D0D0D",
            // height: "100%",
          }}
        >
          <Box p={4} position="relative" mt={8}>
            <Carousel
              autoPlay
              showArrows={false}
              showThumbs={false}
              showStatus={false}
              swipeable={true}
              renderIndicator={renderIndicator}
              infiniteLoop
            >
              <div>
                <img
                  alt=""
                  src={onboard1}
                  style={{ width: "350px", height: "350px" }}
                />
                <p className={classes.onboard}>Get Socialize</p>
                <p className={classes.desc}>
                  Post like share content and connect to different people around
                  the globe.
                </p>
              </div>
              <div>
                <img
                  alt=""
                  src={onboard2}
                  style={{ width: "350px", height: "350px" }}
                />
                <p className={classes.onboard}>A new NFT experience</p>
                <p className={classes.desc}>
                  Discover collect and sell extraordinary NFTs on the best
                  marketplace.
                </p>
              </div>
              <div>
                <img
                  alt=""
                  src={onboard3}
                  style={{ width: "350px", height: "350px" }}
                />
                <p className={classes.onboard}>Get Answers to Your Questions</p>
                <p className={classes.desc}>
                  Have questions about cryptocurrency? PuchOOO is the perfect
                  place to ask.
                </p>
              </div>
            </Carousel>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default RegisterForm;
