import { useState, useRef, useEffect } from "react";
import { useParams, useHistory, Link as RouterLink } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
// import * as dotenv from "dotenv";
import { GET_USER } from "../graphql/queries";
import { REGISTER_USER, REWARD_USER, MINT_NFT } from "../graphql/mutations";
import RecentQuestions from "../components/RecentQuestions";
import LoadingSpinner from "../components/LoadingSpinner";
import PersonIcon from "@material-ui/icons/Person";
import WalletIcon from "@material-ui/icons/AccountBalanceWallet";
import { useStateContext } from "../context/state";
import { formatDateAgo, getErrorMsg } from "../utils/helperFuncs";
import { abi, IR_CONTRACT_ADDRESS } from "../constants";
import { initVenomConnect } from "../venom-connect/configure";
import {
  TextField,
  Avatar,
  Typography,
  Divider,
  Button,
  InputAdornment,
  Paper,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useMintPageStyles } from "../styles/muiStyles";
import { useAuthContext } from "../context/auth";

// import ConnectWallet from "../components/ConnectWallet";
import { pinFileToIPFS } from "../utils/pinata";
// import {
//   connectWallet,
//   getCurrentWalletConnected,
//   mintNFT,
// } from "../utils/interact.js";
import {
  ProviderRpcClient,
  Address,
  Contract,
  Subscriber,
} from "everscale-inpage-provider";

import { NFT_ABI, COLLECTION_ADDR } from "./../constants/nft-abi";

import { set } from "date-fns";

const MintPage = () => {
  const history = useHistory();
  const { user } = useAuthContext();
  const [venomConnect, setVenomConnect] = useState();
  const [venomProvider, setVenomProvider] = useState();
  const [address, setAddress] = useState();
  const [pubKey, setPubkey] = useState("");
  const [mintSuccessful, setMintSuccessful] = useState(false);
  const [status, setStatus] = useState("");
  const classes = useMintPageStyles();
  const { notify } = useStateContext();
  const [errorMsg, setErrorMsg] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");
  const [file, setFile] = useState(null);
  const [imgSrc, setImgSrc] = useState("");
  const [isConfirmed, setConfirmed] = useState(false);

  const init = async () => {
    const _venomConnect = await initVenomConnect();
    setVenomConnect(_venomConnect);
  };

  useEffect(() => {
    init();
  }, []);

  const login = async () => {
    if (!venomConnect) return;
    await venomConnect.connect();
  };

  const getAddress = async (provider) => {
    const providerState = await provider?.getProviderState?.();
    return providerState?.permissions.accountInteraction?.address.toString();
  };

  const getPubkey = async (provider) => {
    const providerState = await provider?.getProviderState?.();
    return providerState?.permissions.accountInteraction?.publicKey;
  };

  // Any interaction with venom-wallet (address fetching is included) needs to be authentificated
  const checkAuth = async (_venomConnect) => {
    const auth = await _venomConnect?.checkAuth();
    if (auth) await getAddress(_venomConnect);
  };

  // This handler will be called after venomConnect.login() action
  // connect method returns provider to interact with wallet, so we just store it in state
  const onConnect = async (provider) => {
    setVenomProvider(provider);
    await onProviderReady(provider);
  };
  const onProviderReady = async (provider) => {
    const venomWalletAddress = provider
      ? await getAddress(provider)
      : undefined;
    const publicKey = provider ? await getPubkey(provider) : undefined;
    setAddress(venomWalletAddress);
    setPubkey(publicKey);
  };
  useEffect(() => {
    // connect event handler
    const off = venomConnect?.on("connect", onConnect);
    if (venomConnect) {
      checkAuth(venomConnect);
    }
    // just an empty callback, cuz we don't need it
    return () => {
      off?.();
    };
  }, [venomConnect]);

  const onDisconnect = async () => {
    venomProvider?.disconnect();
    setAddress("");
  };

  const [nftMint, { loading: nftMintLoading }] = useMutation(MINT_NFT, {
    onError: (err) => {
      setErrorMsg(getErrorMsg(err));
    },
  });
  useEffect(() => {}, [url]);
  useEffect(() => {
    setMintSuccessful(mintSuccessful);
  }, [mintSuccessful]);
  useEffect(() => {
    setStatus(status);
  }, [status]);

  // handle file upload
  const handleFile = (e) => {
    setFile(e.target.files[0]);
    if (e.target.files.length !== 0) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImgSrc(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const deleteImage = () => {
    setFile("");
    setImgSrc("");
    setURL("");
  };

  const uploadToIpfs = async () => {
    const imgHash = await pinFileToIPFS(file);
    setURL("https://gateway.pinata.cloud/ipfs/" + imgHash);
    notify("File uploaded to IPFS");
    notify("JSON data uploaded to IPFS");
    setConfirmed(true);
  };

  const mintNFT = async (url, name, description) => {
    if (url.trim() == "" || name.trim() == "" || description.trim() == "") {
      setMintSuccessful(false);
      setStatus("❗Please make sure all fields are completed before minting.");
    }
    const contr = new venomProvider.Contract(NFT_ABI, COLLECTION_ADDR);
    const { count: id } = await contr.methods
      .totalSupply({ answerId: 0 })
      .call();
    //make metadata
    // const metadata = new Object();
    // metadata.name = name;
    // metadata.image = url;
    // metadata.description = description;

    // console.log(metadata)
    const json = {
      type: "Basic NFT",
      id: parseInt(id) + 1,
      name: name,
      description: description,
      preview: {
        source: url,
        mimetype: "image/png",
      },
      files: [
        {
          source: url,
          mimetype: "image/jpg",
        },
      ],
      external_url: "test",
    };
    contr.methods
      .mintNft({ json: JSON.stringify(json) })
      .send({
        from: new Address(address),
        amount: "1000000000",
      })
      .then((tx) => {
        // Transaction was successful
        if (tx.resultCode == "0") {
          setMintSuccessful(true);

          nftMint({
            variables: {
              collectionInput: {
                mintedBy: user.username,
                owner: address,
                title: name,
                description: description,
                imgLink: url,
              },
            },

            update: (_, { data }) => {
              setTimeout(function () {
                console.log("mintSuccessful", data);
                setStatus(
                  "✅ Check out your transaction on Venomscan: https://testnet.venomscan.com/"
                );
                history.push("/marketplace");
                notify("Collection added!");
              }, 2000);
            },
          });
        }
      })
      .catch((error) => {
        setMintSuccessful(false);
        setStatus(`😥 Something went wrong: ${error.message}`);
      });
  };

  const onMintPressed = async () => {
    mintNFT(url, name, description)
      .then(() => {
        setStatus(status);

        if (mintSuccessful) {
          nftMint({
            variables: {
              collectionInput: {
                mintedBy: user.username,
                owner: address,
                title: name,
                description: description,
                imgLink: url,
              },
            },
            update: (_, { data }) => {
              setTimeout(function () {
                history.push("/marketplace");
                notify("Collection added!");
              }, 2000);
            },
          });

          setName("");
          setDescription("");
          setURL("");
          setImgSrc("");
          setFile("");
        }
      })
      .catch((error) => {
        // Handle the error here
        console.error("An error occurred:", error);
      });
  };

  return (
    <>
      {user ? (
        <div className={classes.root}>
          <div className={classes.userCard}>
            <Avatar src={url} alt={name} className={classes.nft} />
            <Typography
              variant="h5"
              color="secondary"
              className={classes.cardText}
            >
              {address && isConfirmed ? (
                <Button
                  style={{ marginTop: "1em" }}
                  color="primary"
                  variant="contained"
                  size="large"
                  fullWidth
                  // disabled={}
                  onClick={onMintPressed}
                >
                  Mint
                </Button>
              ) : !isConfirmed ? (
                <Alert style={{ marginTop: "1em" }} severity="error">
                  Please confirm your image first
                </Alert>
              ) : (
                <Button
                  className={classes.button}
                  variant="contained"
                  size="large"
                  fullWidth
                  startIcon={<WalletIcon />}
                  onClick={login}
                >
                  Connect Venom Wallet
                </Button>
              )}
            </Typography>
          </div>
          <div className={classes.infoCard}>
            <div className={classes.recentActivity}>
              <form>
                <div style={{ marginBottom: "1em" }}>
                  <Typography variant="h6" color="primary">
                    Name
                  </Typography>
                  <div className={classes.inputField}>
                    <TextField
                      required
                      fullWidth
                      value={name}
                      name="nftName"
                      type="text"
                      label="e.g. My first Puchooo NFT"
                      variant="outlined"
                      size="small"
                      onChange={(event) => setName(event.target.value)}
                    />
                  </div>
                  <Divider />
                </div>
                <div>
                  <Typography variant="h6" color="primary">
                    Description
                  </Typography>
                  <div className={classes.inputField}>
                    <TextField
                      required
                      fullWidth
                      value={description}
                      name="nftDescription"
                      type="text"
                      label="e.g. east or west puchooo is the best ;)"
                      variant="outlined"
                      size="small"
                      onChange={(event) => setDescription(event.target.value)}
                    />
                  </div>
                  <Divider />
                </div>
                <div style={{ marginTop: "1em" }}>
                  <Typography variant="h6" color="primary">
                    Link
                  </Typography>
                  <Divider />
                  <div className={classes.inputField}>
                    <TextField
                      required
                      fullWidth
                      value={url}
                      name="nftLink"
                      type="text"
                      label="e.g. https://gateway.pinata.cloud/ipfs/<hash>"
                      variant="outlined"
                      size="small"
                      onChange={(event) => setURL(event.target.value)}
                    />
                  </div>
                </div>
                {url && !isConfirmed ? (
                  <div>
                    <Button
                      style={{ marginTop: "1em" }}
                      color="primary"
                      variant="contained"
                      size="large"
                      fullWidth
                      onClick={() => {
                        setConfirmed(true);
                      }}
                      // disabled={transaction}
                    >
                      Confirm Url
                    </Button>
                  </div>
                ) : (
                  ""
                )}
                <div style={{ marginTop: "1em" }}>
                  <Typography variant="h6" color="primary">
                    Or Upload
                  </Typography>
                  <Divider />
                  <input
                    accept="image/*"
                    id="upload-company-logo"
                    onChange={handleFile}
                    type="file"
                    hidden
                  />
                  {file ? (
                    <div>
                      <Button
                        style={{ marginTop: "1em" }}
                        color="primary"
                        variant="contained"
                        size="large"
                        fullWidth
                        onClick={uploadToIpfs}
                        // disabled={transaction}
                      >
                        Confirm Image
                      </Button>
                      <Divider />
                      <Button
                        style={{ marginTop: "1em" }}
                        color="primary"
                        variant="contained"
                        size="large"
                        fullWidth
                        onClick={deleteImage}
                        // disabled={transaction}
                      >
                        Want to Change Image ?
                      </Button>
                    </div>
                  ) : (
                    <label htmlFor="upload-company-logo">
                      <Button
                        style={{
                          marginTop: "1em",
                          background:
                            "linear-gradient(91.61deg, #003638 0%, #72C974 100%)",
                        }}
                        color="primary"
                        variant="contained"
                        size="large"
                        fullWidth
                        component="span"
                        // disabled={transaction}
                      >
                        Choose File
                      </Button>
                    </label>
                  )}
                  <Typography variant="p" color="primary">
                    {file && <p>{file.name}</p>}
                  </Typography>
                </div>

                <div style={{ marginTop: "1em" }}>
                  <Divider />
                  <Typography variant="h6" color="primary">
                    {status}
                  </Typography>
                </div>
                <div></div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.root}>
          <div>
            <Alert style={{ marginTop: "1em" }} severity="error">
              Please login to your account first
            </Alert>
          </div>
        </div>
      )}
    </>
  );
};

export default MintPage;
