import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { LOGIN_USER, WALLET_LOGIN, WALLET_AUTH } from "../graphql/mutations";
import { useAuthContext } from "../context/auth";
import { useStateContext } from "../context/state";
// import { ethers } from "ethers";
import ErrorMessage from "./ErrorMessage";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import logoImg from "../assets/logo.png";
import { getErrorMsg } from "../utils/helperFuncs";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  TextField,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Link,
  // Grid,
  Box,
  // Checkbox,
  useMediaQuery,
} from "@material-ui/core";
import { Grid } from "@mui/material";
import { useAuthFormStyles } from "../styles/muiStyles";
import PersonIcon from "@material-ui/icons/Person";
import WalletIcon from "@material-ui/icons/AccountBalanceWallet";
import LockIcon from "@material-ui/icons/Lock";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import onboard1 from "../svg/onboard-1.svg";
import onboard2 from "../svg/onboard-2.svg";
import onboard3 from "../svg/onboard-3.svg";

import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
// import ConnectWallet from "./ConnectWallet";
import { useTheme } from "@material-ui/core/styles";
import { initVenomConnect } from "../venom-connect/configure";
import { Base64 } from "js-base64";

const validationSchema = yup.object({
  username: yup.string().required("Required"),
  password: yup.string().required("Required"),
});

const LoginForm = ({ setAuthType, closeModal }) => {
  const [venomConnect, setVenomConnect] = useState();
  const [venomProvider, setVenomProvider] = useState();
  const [address, setAddress] = useState();
  const [pubKey, setPubkey] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const classes = useAuthFormStyles();
  const { user, setUser } = useAuthContext();
  const { notify, fcmToken } = useStateContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("900"));
  const checkHeight = useMediaQuery("(max-height:775px)");

  const init = async () => {
    const _venomConnect = await initVenomConnect();
    setVenomConnect(_venomConnect);
  };

  useEffect(() => {
    init();
  }, []);

  const login = async () => {
    if (!venomConnect) return;
    await venomConnect.connect();
  };

  const getAddress = async (provider) => {
    const providerState = await provider?.getProviderState?.();
    return providerState?.permissions.accountInteraction?.address.toString();
  };

  const getPubkey = async (provider) => {
    const providerState = await provider?.getProviderState?.();
    return providerState?.permissions.accountInteraction?.publicKey;
  };

  // Any interaction with venom-wallet (address fetching is included) needs to be authentificated
  const checkAuth = async (_venomConnect) => {
    const auth = await _venomConnect?.checkAuth();
    if (auth) await getAddress(_venomConnect);
  };

  // This handler will be called after venomConnect.login() action
  // connect method returns provider to interact with wallet, so we just store it in state
  const onConnect = async (provider) => {
    setVenomProvider(provider);
    await onProviderReady(provider);
  };
  const onProviderReady = async (provider) => {
    const venomWalletAddress = provider
      ? await getAddress(provider)
      : undefined;
    const publicKey = provider ? await getPubkey(provider) : undefined;
    setAddress(venomWalletAddress);
    setPubkey(publicKey);
  };
  useEffect(() => {
    // connect event handler
    const off = venomConnect?.on("connect", onConnect);
    if (venomConnect) {
      checkAuth(venomConnect);
    }
    // just an empty callback, cuz we don't need it
    return () => {
      off?.();
    };
  }, [venomConnect]);

  const onDisconnect = async () => {
    venomProvider?.disconnect();
    setAddress("");
  };

  const { register, handleSubmit, reset, errors } = useForm({
    mode: "onTouched",
    resolver: yupResolver(validationSchema),
  });

  const [loginUser, { loading }] = useMutation(LOGIN_USER, {
    onError: (err) => {
      setErrorMsg(getErrorMsg(err));
    },
  });

  const [walletauth] = useMutation(WALLET_AUTH, {
    update(_, { data: { walletAuth } }) {
      setUser(walletAuth);
      notify(`Welcome, ${walletAuth.account}! You're logged in.`);
      reset();
      closeModal();
    },
  });

  const [walletlogin] = useMutation(WALLET_LOGIN, {
    variables: { account: address },
    onError: (err) => {
      setErrorMsg(getErrorMsg(err));
    },

    onCompleted({ walletLogin }) {
      handleSignMessage(walletLogin);
    },
  });

  const handleSignMessage = async ({ nonce, account, username }) => {
    const data = `Hi ${username} ! Your Unique Login Code: ${nonce}`;
    // Sign data with hashing
    venomProvider
      .signData({
        publicKey: pubKey,
        data: Base64.encode(data),
      })
      .then((signedData) => {
        walletauth({
          variables: {
            account: address,
            pubKey,
            signature: signedData.signature,
            dataHash: signedData.dataHash,
          },
        });
      })
      .catch((error) => {
        window.alert(
          "Failure" + (error && error.message ? `\n\n${error.message}` : "")
        );
      });
  };

  const onLogin = ({ username, password }) => {
    console.log("front-end-fcm-token: ", fcmToken);
    loginUser({
      variables: { username, password, fcmToken },
      update: (_, { data }) => {
        setUser(data.login);
        notify(`Welcome, ${data.login.username}! You're logged in.`);
        reset();
        closeModal();
      },
    });
  };

  const onWalletLogin = () => {
    walletlogin({
      variables: { account: address },
      update: (_, { data }) => {
        setUser(data.walletLogin);
        notify(`Welcome, ${data.walletLogin.account}! You're logged in.`);
        reset();
        closeModal();
      },
    });
  };

  const renderIndicator = (onClickHandler, isSelected, index, label) => {
    // Customize the CSS of the indicator dots here
    const dotColor = isSelected ? "#A6E3A7" : "#ffffff";
    const dotStyle = {
      backgroundColor: dotColor,
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      margin: "0 5px",
      display: "inline-block",
      cursor: "pointer",
    };

    return (
      <li
        style={dotStyle}
        onClick={onClickHandler}
        key={index}
        role="button"
        aria-label={label}
        tabIndex={0}
      />
    );
  };

  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        height:
          checkHeight && isMobile ? "100%" : checkHeight ? undefined : "100%",
      }}
    >
      <Grid
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        sx={{ backgroundColor: "rgba(28, 28, 28, 1)" }}
      >
        <Box
          // p={4}
          borderRadius={4}
          maxWidth="400px"
          mx="auto"
          textAlign="center"
          className={classes.boxContainer}
        >
          <img src={logoImg} alt="Logo" />
          <Typography
            variant="h3"
            className={classes.welcomeTxt}
            // style={{ textAlign: "left", fontWeight: "bold" }}
          >
            Welcome Back!
          </Typography>

          <Box mt={2} mb={1}>
            <form onSubmit={handleSubmit(onLogin)}>
              <div className={classes.inputField}>
                <TextField
                  fullWidth
                  inputRef={register}
                  name="username"
                  type="text"
                  label="Username"
                  variant="outlined"
                  size="small"
                  error={"username" in errors}
                  helperText={
                    "username" in errors ? errors.username.message : ""
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className={classes.inputField}>
                <TextField
                  required
                  fullWidth
                  inputRef={register}
                  name="password"
                  type={showPass ? "text" : "password"}
                  label="Password"
                  variant="outlined"
                  size="small"
                  error={"password" in errors}
                  helperText={
                    "password" in errors ? errors.password.message : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPass((prevState) => !prevState)}
                          size="small"
                        >
                          {showPass ? (
                            <VisibilityOffIcon color="secondary" />
                          ) : (
                            <VisibilityIcon color="secondary" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <Button
                color="primary"
                variant="contained"
                size="large"
                fullWidth
                startIcon={<ExitToAppIcon />}
                type="submit"
                disabled={loading}
                style={{
                  background:
                    "linear-gradient(91.61deg, #003638 0%, #72C974 100%)",
                }}
              >
                Log In
              </Button>
            </form>
            {/* <Typography variant="body1" className={classes.footerText}>
              OR
            </Typography> */}
          </Box>

          {/* <Box mt={2} mb={4}>
            <TextField
              required
              fullWidth
              inputRef={register}
              name="account"
              type="text"
              label="Wallet Account"
              variant="standard"
              size="small"
              value={address}
              error={"account" in errors}
              helperText={"account" in errors ? errors.username.message : ""}
              InputLabelProps={{ required: false }}
            />
          </Box> */}

          <div>
            {address ? (
              <>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  startIcon={<WalletIcon />}
                  onClick={onDisconnect}
                  style={{
                    background: "#D30000",
                    marginBottom: "1em",
                  }}
                >
                  Disconnect Venom Wallet
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  startIcon={<WalletIcon />}
                  onClick={walletlogin}
                  style={{
                    background:
                      "linear-gradient(91.61deg, #003638 0%, #72C974 100%)",
                  }}
                >
                  Log In Using Wallet
                </Button>
              </>
            ) : (
              ""
            )}
          </div>
          {!address ? (
            <Button
              className={classes.button}
              variant="contained"
              size="large"
              fullWidth
              startIcon={<WalletIcon />}
              disabled={loading}
              onClick={login}
            >
              Connect Venom Wallet
            </Button>
          ) : (
            ""
          )}
          <Box
            display="flex"
            flexDirection={"column"}
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Typography variant="body1" className={classes.footerText}>
                Don’t have an account?{" "}
                <Link
                  onClick={() => setAuthType("signup")}
                  className={classes.link}
                >
                  Sign Up
                </Link>
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant="body1" className={classes.footerText}>
                Forgot password?{" "}
                <Link
                  onClick={() => setAuthType("forgotpassword")}
                  className={classes.link}
                >
                  Reset
                </Link>
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant="body1" className={classes.footerText}>
                Account not verified?{" "}
                <Link
                  onClick={() => setAuthType("verify")}
                  className={classes.link}
                >
                  verify
                </Link>
              </Typography>
            </Box>
          </Box>
          <ErrorMessage
            errorMsg={errorMsg}
            clearErrorMsg={() => setErrorMsg(null)}
          />
        </Box>
      </Grid>
      {!isMobile && (
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          className="hidden sm:block"
          sx={{ background: "#0D0D0D" }}
        >
          <Box p={4} position="relative" mt={8}>
            <Carousel
              autoPlay
              showArrows={false}
              showThumbs={false}
              showStatus={false}
              swipeable={true}
              renderIndicator={renderIndicator}
              infiniteLoop
            >
              <div>
                <img
                  alt=""
                  src={onboard1}
                  style={{ width: "350px", height: "350px" }}
                />
                <p className={classes.onboard}>Get Socialize</p>
                <p className={classes.desc}>
                  Post like share content and connect to different people around
                  the globe.
                </p>
              </div>
              <div>
                <img
                  alt=""
                  src={onboard2}
                  style={{ width: "350px", height: "350px" }}
                />
                <p className={classes.onboard}>A new NFT experience</p>
                <p className={classes.desc}>
                  Discover collect and sell extraordinary NFTs on the best
                  marketplace.
                </p>
              </div>
              <div>
                <img
                  alt=""
                  src={onboard3}
                  style={{ width: "350px", height: "350px" }}
                />
                <p className={classes.onboard}>Get Answers to Your Questions</p>
                <p className={classes.desc}>
                  Have questions about cryptocurrency? PuchOOO is the perfect
                  place to ask.
                </p>
              </div>
            </Carousel>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default LoginForm;
