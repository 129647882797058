import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useParams, Link as RouterLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { UPDATE_PROFILE } from "../graphql/mutations";
import { GET_USER } from "../graphql/queries";
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useApolloClient,
} from "@apollo/client";
import { LOGGED_USER_DETAILS } from "../graphql/fragments";
import { useStateContext } from "../context/state";
import ErrorMessage from "./ErrorMessage";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { getErrorMsg } from "../utils/helperFuncs";
import { pinFileToIPFS } from "../utils/pinata";
import WalletIcon from "@material-ui/icons/AccountBalanceWallet";
import {
  TextField,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Link,
  Chip,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useAuthContext } from "../context/auth";
import { useAskQuesPageStyles } from "../styles/muiStyles";
import { initVenomConnect } from "../venom-connect/configure";
import ConnectWallet from "./ConnectWallet";

const validationSchema = yup.object({
  username: yup
    .string()
    .max(20, "Must be at mosst 20 characters")
    .min(3, "Must be at least 3 characters")
    .matches(
      /^[a-zA-Z0-9-_]*$/,
      "Only alphanum, dash & underscore characters are allowed"
    ),
});

const UpdateProfileForm = ({ setAuthType, closeModal }) => {
  const [venomConnect, setVenomConnect] = useState();
  const [venomProvider, setVenomProvider] = useState();
  const [address, setAddress] = useState();
  const [pubKey, setPubkey] = useState("");
  const [showPass, setShowPass] = useState(false);
  // const { username } = useParams();
  const [fetchedUser, setFetchedUser] = useState(null);
  const [userID, setUserID] = useState("");
  const { user, setUser } = useAuthContext();
  console.log("change hua", user);
  const classes = useAskQuesPageStyles();
  const history = useHistory();
  const { editValues, clearEdit, notify } = useStateContext();
  const [tagInput, setTagInput] = useState("");
  const [tags, setTags] = useState(editValues ? editValues.tags : []);
  const [errorMsg, setErrorMsg] = useState(null);
  const [url, setURL] = useState("");
  const [file, setFile] = useState(null);
  const [imgSrc, setImgSrc] = useState("");
  const [isConfirmed, setConfirmed] = useState(false);
  const init = async () => {
    const _venomConnect = await initVenomConnect();
    setVenomConnect(_venomConnect);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {}, [user]);
  const login = async () => {
    if (!venomConnect) return;
    await venomConnect.connect();
  };

  const getAddress = async (provider) => {
    const providerState = await provider?.getProviderState?.();
    return providerState?.permissions.accountInteraction?.address.toString();
  };

  const getPubkey = async (provider) => {
    const providerState = await provider?.getProviderState?.();
    return providerState?.permissions.accountInteraction?.publicKey;
  };

  // Any interaction with venom-wallet (address fetching is included) needs to be authentificated
  const checkAuth = async (_venomConnect) => {
    const auth = await _venomConnect?.checkAuth();
    if (auth) await getAddress(_venomConnect);
  };

  // This handler will be called after venomConnect.login() action
  // connect method returns provider to interact with wallet, so we just store it in state
  const onConnect = async (provider) => {
    setVenomProvider(provider);
    await onProviderReady(provider);
  };
  const onProviderReady = async (provider) => {
    const venomWalletAddress = provider
      ? await getAddress(provider)
      : undefined;
    const publicKey = provider ? await getPubkey(provider) : undefined;
    setAddress(venomWalletAddress);
    setPubkey(publicKey);
  };
  useEffect(() => {
    // connect event handler
    const off = venomConnect?.on("connect", onConnect);
    if (venomConnect) {
      checkAuth(venomConnect);
    }
    // just an empty callback, cuz we don't need it
    return () => {
      off?.();
    };
  }, [venomConnect]);

  const onDisconnect = async () => {
    venomProvider?.disconnect();
    setAddress("");
  };

  const { register, handleSubmit, reset, errors } = useForm({
    mode: "onTouched",
    resolver: yupResolver(validationSchema),
  });

  const [fetchUser, { data, loading: userLoad }] = useLazyQuery(GET_USER, {
    onError: (err) => {
      notify(getErrorMsg(err), "error");
    },
  });
  const [updateProfile, { loading }] = useMutation(UPDATE_PROFILE, {
    // refetchQueries: [{ query: GET_USER }],
    onError: (err) => {
      setErrorMsg(getErrorMsg(err));
    },
  });

  const editProfile = ({ username }) => {
    updateProfile({
      variables: { username, account: address },
      update: (_, { data }) => {
        setUser(data.updateUserProfile);
        history.push(`/user/${data.updateUserProfile.username}`);
        clearEdit();
        notify("Profile Update!");
      },
    });
  };

  const handleFile = (e) => {
    setFile(e.target.files[0]);
    if (e.target.files.length !== 0) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImgSrc(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const uploadToIpfs = async () => {
    const imgHash = await pinFileToIPFS(file);
    setURL("https://gateway.pinata.cloud/ipfs/" + imgHash);
    notify("File uploaded to IPFS");
    notify("JSON data uploaded to IPFS");
    setConfirmed(true);
  };
  return (
    <div className={classes.root}>
      <Typography variant="h5" color="secondary">
        Edit Profile
      </Typography>
      <form onSubmit={handleSubmit(editProfile)}>
        <div className={classes.inputWrapper}>
          <TextField
            fullWidth
            inputRef={register}
            name="username"
            placeholder={user ? user.username : ""}
            type="text"
            label="Change username"
            variant="outlined"
            size="small"
            error={"username" in errors}
            helperText={"username" in errors ? errors.username.message : ""}
            className={classes.inputField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <div></div>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className={classes.inputWrapper}>
          <TextField
            fullWidth
            inputRef={register}
            name="account"
            type="text"
            label="Wallet Account"
            placeholder={user.account ? user.account : "Add your wallet here"}
            variant="outlined"
            size="small"
            value={address ? address : ""}
            error={"account" in errors}
            helperText={"account" in errors ? errors.username.message : ""}
            className={classes.inputField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <div></div>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className={classes.inputWrapper}>
          {address ? (
            <Button
              variant="contained"
              size="large"
              fullWidth
              startIcon={<WalletIcon />}
              onClick={onDisconnect}
              style={{
                background: "#D30000",
                marginBottom: "1em",
              }}
            >
              Disconnect Venom Wallet
            </Button>
          ) : (
            ""
          )}
        </div>
        <div className={classes.inputWrapper}>
          {!address ? (
            <Button
              className={classes.button}
              variant="contained"
              size="large"
              fullWidth
              startIcon={<WalletIcon />}
              onClick={login}
            >
              Connect Venom Wallet
            </Button>
          ) : (
            ""
          )}
        </div>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          size="large"
          className={classes.submitBtn}
          disabled={loading}
        >
          Save
        </Button>
        <ErrorMessage
          errorMsg={errorMsg}
          clearErrorMsg={() => setErrorMsg(null)}
        />
      </form>
    </div>
  );
};

export default UpdateProfileForm;
