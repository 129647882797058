import { useState, useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_USER } from "../graphql/queries";
import { REWARD_USER } from "../graphql/mutations";
import { useParams, Link as RouterLink, useHistory } from "react-router-dom";
import { useStateContext } from "../context/state";
import { useAuthContext } from "../context/auth";
import { abi, IR_CONTRACT_ADDRESS } from "../constants";
import Alert from "@material-ui/lab/Alert";
import WalletIcon from "@material-ui/icons/AccountBalanceWallet";
import { getErrorMsg } from "../utils/helperFuncs";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ConnectWallet from "../components/ConnectWallet";
import LoadingSpinner from "../components/LoadingSpinner";
import rew from "../svg/reward.svg";
import rep from "../svg/reputation.svg";
import {
  TextField,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Link,
  Box,
  Checkbox,
  useMediaQuery,
} from "@material-ui/core";
import { Grid } from "@mui/material";
import { useQuesListStyles, useRewardPageStyle } from "../styles/muiStyles";
import { useTheme } from "@material-ui/core/styles";
import {
  ProviderRpcClient,
  Address,
  Contract,
} from "everscale-inpage-provider";
// import { EverscaleStandaloneClient } from "everscale-standalone-client/nodejs";
import { initVenomConnect } from "../venom-connect/configure";
import SampleAbi from "../constants/Sample.abi.json";
import tokenWalletAbi from "../abi/TokenWallet.abi.json";
import tokenRootAbi from "../abi/TokenRoot.abi.json";

const RewardPage = () => {
  const { clearEdit, notify } = useStateContext();
  const [venomConnect, setVenomConnect] = useState();
  const [venomProvider, setVenomProvider] = useState();
  const [address, setAddress] = useState();
  const [pubKey, setPubkey] = useState("");
  const { user } = useAuthContext();
  const [quesData, setQuesData] = useState(null);
  const [sortBy, setSortBy] = useState("HOT");
  const [page, setPage] = useState(1);
  const classes = useRewardPageStyle();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [username, setUsername] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [fetchedUser, setFetchedUser] = useState(null);
  const [userID, setUserID] = useState("");
  const [reward, setReward] = useState("");
  const [balance, setBalance] = useState("");
  const [transaction, setTransaction] = useState(false);

  const init = async () => {
    const _venomConnect = await initVenomConnect();
    setVenomConnect(_venomConnect);
  };

  useEffect(() => {
    init();
  }, []);

  const getAddress = async (provider) => {
    const providerState = await provider?.getProviderState?.();
    return providerState?.permissions.accountInteraction?.address.toString();
  };

  const getPubkey = async (provider) => {
    const providerState = await provider?.getProviderState?.();
    return providerState?.permissions.accountInteraction?.publicKey;
  };

  // Any interaction with venom-wallet (address fetching is included) needs to be authentificated
  const checkAuth = async (_venomConnect) => {
    const auth = await _venomConnect?.checkAuth();
    if (auth) await getAddress(_venomConnect);
  };

  // This handler will be called after venomConnect.login() action
  // connect method returns provider to interact with wallet, so we just store it in state
  const onConnect = async (provider) => {
    setVenomProvider(provider);
    await onProviderReady(provider);
  };
  const onProviderReady = async (provider) => {
    const venomWalletAddress = provider
      ? await getAddress(provider)
      : undefined;
    const publicKey = provider ? await getPubkey(provider) : undefined;
    setAddress(venomWalletAddress);
    setPubkey(publicKey);
  };
  useEffect(() => {
    // connect event handler
    const off = venomConnect?.on("connect", onConnect);
    if (venomConnect) {
      checkAuth(venomConnect);
    }
    // just an empty callback, cuz we don't need it
    return () => {
      off?.();
    };
  }, [venomConnect]);

  const [fetchUser, { data, loading }] = useLazyQuery(GET_USER, {
    onError: (err) => {
      notify(getErrorMsg(err), "error");
    },
  });
  const [userReward, { data: rewardData, loading: rewardloading, error }] =
    useMutation(REWARD_USER, {
      onError: (err) => {
        setErrorMsg(getErrorMsg(err));
      },
    });
  useEffect(() => {
    if (user) {
      setUsername(user.username);
      console.log(user);
    }
  }, [user]);
  useEffect(() => {
    fetchUser({ variables: { username } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  useEffect(() => {
    if (data) {
      setFetchedUser(data.getUser);
      setUserID(data.getUser.id);
    }
  }, [data]);

  // if (loading || !fetchedUser) {
  //   return (
  //     <div style={{ minWidth: "100%", marginTop: "20%" }}>
  //       <LoadingSpinner size={80} />
  //     </div>
  //   );
  // }

  // if (user) {
  //   const {
  //     id,
  //     account: account,
  //     rewards: rewards,
  //     username: userName,
  //     createdAt,
  //     reputation,
  //     totalQuestions,
  //     totalAnswers,
  //     recentQuestions,
  //     recentAnswers,
  //   } = fetchedUser;

  //   setReward(rewards);
  // }

  const ClaimRewards = async () => {
    if (user) {
      const {
        id,
        account: account,
        rewards: rewards,
        username: userName,
        createdAt,
        reputation,
        totalQuestions,
        totalAnswers,
        recentQuestions,
        recentAnswers,
      } = fetchedUser;
      console.log(rewards);

      if (rewards === "notClaimed") {
        setTransaction(true);
        if (reputation >= 11) {
          const contr = new venomProvider.Contract(
            SampleAbi,
            "0:62789e66787c66b0080cbbb9ff6eaf63a1dd94a4b72be444e85918fa3aa97047"
            //"0:c4b0ad81a31e4cb7f9de3b83f9ba7792d047193601356030ebeb11d1c9ccdad3"
          );
          console.log(contr);
          await contr.methods
            .claim({
              recipient: address,
            })
            .sendExternal({
              withoutSignature: true,
            })
            .then((tx) => {
              if (tx) {
                userReward({
                  variables: {
                    id: userID.toString(),
                    input: { rewards: "Claimed" },
                  },
                  update: (_, { data }) => {
                    notify(`You've successfully claimed.`);
                    setTimeout(function () {
                      window.location.reload(true);
                    }, 3000);
                  },
                });
              }
            })
            .catch((er) => {
              console.log("error", er);
              notify(`Transaction failed ${er} .`, "error");
            });
        } else {
          alert("Your reputation points are not enough to unlock this!");
        }
      } else {
        alert("Your already claimed!");
      }
    } else {
      alert("Please Login First!");
    }
  };
  const renderIndicator = (onClickHandler, isSelected, index, label) => {
    // Customize the CSS of the indicator dots here
    const dotColor = isSelected ? "#A6E3A7" : "#ffffff";
    const dotStyle = {
      backgroundColor: dotColor,
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      margin: "0 5px",
      display: "inline-block",
      cursor: "pointer",
    };
    return (
      <li
        style={dotStyle}
        onClick={onClickHandler}
        key={index}
        role="button"
        aria-label={label}
        tabIndex={0}
      />
    );
  };

  return (
    <>
      <div className={classes.root}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Grid item xs={12} sm={12}>
            <h1 className={classes.rewardsMainHead}>Your awaiting rewards</h1>
          </Grid>
          <Grid item xs={12} sm={12}>
            <h1 className={classes.youLlGet}>What you'll get</h1>
          </Grid>
          <Grid item xs={12} sm={12}>
            <h1 className={classes.rewardsHead}>Rewards</h1>
          </Grid>
          <Grid item xs={12} sm={12} className="hidden sm:block">
            <Box
              p={3}
              position="relative"
              mt={2}
              className={classes.carouselBox}
            >
              <Carousel
                autoPlay
                showArrows={false}
                showThumbs={false}
                showStatus={false}
                renderIndicator={renderIndicator}
                infiniteLoop
              >
                <div>
                  <img alt="" src={rew} className={classes.carouselImages} />
                  <p className={classes.onboard}>100 Reputation</p>
                  <Button
                    className={classes.buttonReward}
                    onClick={ClaimRewards}
                  >
                    Claim
                  </Button>
                </div>
                <div>
                  <img alt="" src={rew} className={classes.carouselImages} />
                  <p className={classes.onboard}>500 Reputation</p>
                  <Button
                    className={classes.buttonReward}
                    onClick={ClaimRewards}
                    disabled
                  >
                    Claim
                  </Button>
                </div>
                <div>
                  <img alt="" src={rew} className={classes.carouselImages} />
                  <p className={classes.onboard}>1000 Reputation</p>
                  <Button
                    className={classes.buttonReward}
                    onClick={ClaimRewards}
                    disabled
                  >
                    Claim
                  </Button>
                </div>
              </Carousel>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Grid item xs={12} sm={12} style={{ marginTop: "5em" }}>
            <h1 className={classes.youLlGet}>How to increase</h1>
          </Grid>
          <Grid item xs={12} sm={12}>
            <h1 className={classes.rewardsHead}>Reputation</h1>
          </Grid>
          <Grid item xs={12} sm={12} className="hidden sm:block">
            <Box
              p={3}
              justifyContent="center"
              position="relative"
              mt={2}
              className={classes.carouselBox}
            >
              <Carousel
                autoPlay
                showArrows={false}
                showThumbs={false}
                showStatus={false}
                swipeable={true}
                renderIndicator={renderIndicator}
                infiniteLoop
              >
                <div>
                  <img alt="" src={rep} className={classes.carouselImages} />
                  <p className={classes.onboard}>Every 10 votes</p>
                  <Button
                    disabled
                    disableRipple
                    className={classes.buttonReputation}
                  >
                    1 Reputation
                  </Button>
                </div>
                <div>
                  <img alt="" src={rep} className={classes.carouselImages} />
                  <p className={classes.onboard}>New user</p>
                  <Button
                    disabled
                    disableRipple
                    className={classes.buttonReputation}
                  >
                    5 Reputation
                  </Button>
                </div>
                <div>
                  <img alt="" src={rep} className={classes.carouselImages} />
                  <p className={classes.onboard}>Every 10 questions answered</p>
                  <Button
                    disabled
                    disableRipple
                    className={classes.buttonReputation}
                  >
                    1 Reputation
                  </Button>
                </div>
              </Carousel>
            </Box>
          </Grid>
        </Grid>
      </div>
      {/* ) : (
        <div className={classes.root}>
          <div>
            <Alert style={{ marginTop: "1em" }} severity="error">
              Please login to your account first
            </Alert>
          </div>
        </div> */}
      {/* )} */}
    </>
  );
};

export default RewardPage;
